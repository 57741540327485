import React, { useEffect, useState } from 'react';
import { ethers } from 'ethers';

const CirculationPage = () => {
  const [totalSupply, setTotalSupply] = useState(null);

  const contractAddress = '0x4c45bbec2ff7810ef4a77ad7bd4757c446fe4155'; // Replace with the actual contract address
  const provider = new ethers.providers.JsonRpcProvider('https://mainnet.infura.io/v3/3ddebb74c04041729b4be1fc6d6f95ff'); // Replace with your Infura API key

  const fetchTotalSupply = async () => {
    try {
      const abi = ['function totalSupply() view returns (uint256)']; // Replace with the ABI of your contract
      const contract = new ethers.Contract(contractAddress, abi, provider);

      const result = await contract.totalSupply();
      const totalSupplyFormatted = parseFloat(ethers.utils.formatEther(result)).toFixed(6);
      setTotalSupply(totalSupplyFormatted);
    } catch (error) {
      console.error('Error fetching total supply: ', error);
    }
  };

  useEffect(() => {
    // Override body style
    document.body.style.backgroundColor = 'white';
    document.body.style.backgroundImage = 'none';
    document.body.style.color = 'black';

    fetchTotalSupply();

    // Cleanup: Reset body styles when the component unmounts
    return () => {
      document.body.style.backgroundColor = '';
      document.body.style.color = '';
    };
  }, []);

  return (
    <div>
      {totalSupply !== null ? <pre>{totalSupply}</pre> : <p></p>}
    </div>
  );
};

export default CirculationPage;
