import { motion } from 'framer-motion';
import React , {useEffect, useRef, useState} from 'react';
import axios from 'axios';
import { ethers } from 'ethers'
import Web3Modal from 'web3modal'
import Header from '../../components/HeaderJngl/Header';


import etherscan from '../../assets/icons/etherscanIcon.png';
import discord from '../../assets/icons/discordIcon.png';
import opensea from '../../assets/icons/openseaIcon.png';
import twitter from '../../assets/icons/twitterIcon.png';
import dex from '../../assets/icons/dexIcon.png';
import emailIcon from '../../assets/icons/emailIcon.png';
import tg from '../../assets/icons/tgIcon.png';

import kongToken from '../../assets/images/kong-token.png'
import token2 from '../../assets/images/token-kong.png'

import './MP.scss';
import { useWeb3Context } from '../../context'

import { toast } from 'react-toastify';
import WalletConnectProvider from '@walletconnect/web3-provider'

const marketplaceAbi = require('../../helper/marketplace.json')
const alphaAbi = require('../../helper/alpha.json')

let web3Modal;

const base_uri = "https://api.supremekong.com";

const providerOptions = {
  walletconnect: {
    package: WalletConnectProvider, // required
  },
}

if (typeof window !== 'undefined') {
  web3Modal = new Web3Modal({
    network: 'mainnet', // optional
    cacheProvider: true,
    providerOptions, // required
  })

  console.log(web3Modal)
}


export default function   Home() {

  axios.defaults.withCredentials = true;

  const headerRef = useRef(null);

  const { web3Provider, connect, disconnect, address } = useWeb3Context()

  const [alphaBalance, setAlphaBalance] = React.useState(0)

  const marketplaceAddress = "0x7A24f6f3E2BA1202E444c7eEB1e8d555377991aa"

  // const [countDown, setCountDown] = React.useState(
  //   countDownDate - new Date().getTime()
  // ); 

  const [Listing, setListing] = React.useState([]);

  const [updateBalance, setUpdateBalance] = useState(0);

  const handleRefreshBalance = () => {
    // Increment the updateBalance state to trigger the balance update in Header
    setUpdateBalance(updateBalance + 1);
  };

  useEffect(async() => {
    //Runs only on the first render
    if(web3Provider){ 
      console.log('web3Provider')
      loadListing()
    }else{
    }
  }, [web3Provider]);

  const [selectedImage, setSelectedImage] = React.useState();

  const handleItemClick = (item) => {
    setSelectedImage(item.image); // You may need to adjust this line depending on your data structure
  };

  const loadListing = async() => {
    try{
    const query = `
      {
        collectionCreateds(first: 100) {
          id
          collectionId
          marketplaceId
          name
          price
        }
      }
      `;
    const listing = await axios.post('https://api.studio.thegraph.com/query/46966/kong-mkt-goerli/version/latest', {query: query},{withCredentials:false})

    let listingData = listing.data.data.collectionCreateds;



    const signer = web3Provider?.getSigner();
    const contract = new ethers.Contract(marketplaceAddress, marketplaceAbi, signer);
    

    const listingPromises = listingData.map(async (listing) => {
      const listingInfo = await contract.getCollectionListing(listing.collectionId);
      

      const priceInEther = ethers.utils.formatEther(listingInfo[2]);
      const holdingInEther = ethers.utils.formatEther(listingInfo[3]);
      // if(parseFloat(holdingInEther) > alphaBalance){
      //   //skip
      //   return;
      // }
      listing.priceDisplay = parseFloat(priceInEther).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2});
      listing.supply = listingInfo[4].toNumber();
      listing.price = listingInfo[2];
      listing.minholding = listingInfo[3];
      listing.image = "https://assets.supremekong.com/images/marketplace/"+ listing.marketplaceId +".png";
      listing.amount = 0;
      return listing;
    });
  
    // Wait for all metadata requests to complete
    listingData = await Promise.all(listingPromises);
    
    console.log(listingData)
    setListing(listingData)

    }catch(err){
      console.log(err)
    }
  }

  const increment = (item) => {
    const updatedListing = Listing.map(listingItem => {
      if (listingItem === item) {
        if (item.amount < item.supply) {
          return {
            ...listingItem,
            amount: item.amount + 1,
          };
        }
      }
      return listingItem;
    });
    setListing(updatedListing);
  };
  
  const decrement = (item) => {
    const updatedListing = Listing.map(listingItem => {
      if (listingItem === item) {
        if (item.amount > 0) {
          return {
            ...listingItem,
            amount: item.amount - 1,
          };
        }
      }
      return listingItem;
    });
    setListing(updatedListing);
  };
  
  
  
  

  const loadToken = async() => {
    const signer = web3Provider?.getSigner();
    const contract = new ethers.Contract("0x4c45bbec2ff7810ef4a77ad7bd4757c446fe4155", alphaAbi, signer);
    const balance = await contract.balanceOf(address);

    console.log(balance)
    
    const balanceInEther = ethers.utils.formatEther(balance);
    const roundedBalanceInEther = Math.ceil(balanceInEther);
    const formattedBalance = roundedBalanceInEther.toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits:0});
    
    setAlphaBalance(formattedBalance);
  }


  const buy = async (id,price,amount) => {

    try{
      console.log(id)
      const signer = web3Provider?.getSigner();
      const contract = new ethers.Contract(marketplaceAddress, marketplaceAbi, signer);
      const tokenContract = new ethers.Contract("0x4c45bbec2ff7810ef4a77ad7bd4757c446fe4155", alphaAbi, signer);

      const totalPrice = price.mul(amount);
      

      // Check if approval is necessary
      const allowance = await tokenContract.allowance(address, marketplaceAddress);
      if (allowance.lt(totalPrice)) {
        // Notify user about the approval request
        toast.info('Requesting Approval');
        
        // Approve the Staking Contract to spend the tokens
        const approveTx = await tokenContract.approve(marketplaceAddress, totalPrice);
        await approveTx.wait();

        // Notify user about the successful approval
        toast.success('Approval Successful');
      }



      const tx = await contract.buyNFT(id,amount)
      await tx.wait()
      toast.success('Transaction Success')

      handleRefreshBalance()
      loadListing()
    }catch(err){
      console.log(err)
      toast.error('Transaction Failed')
    }
  }


  return (
    <>
    <Header siteTitle={`Title`} updateBalance={updateBalance} ref={headerRef}/>
    <div
    id='Home' className='Marketplace'>
      <div className='Marketplace__content' style={{marginTop:'40px'}}>          
        <div className='marketplace-flex-container'>
          

          <div className='marketplace-banner-container'>
            <div style={{display:'flex'}}>
              <div className='marketplace-text-container-top'>
                  MARKETPLACE
              </div>
              {/* <div className='marketplace-text-container' style={{marginLeft:'auto'}}>
                  Your balance : <img ></img> {alphaBalance}
              </div> */}
            </div>
            <div style={{display:'flex'}}>
              <div className='marketplace-text-container-bottom'>
                Hodl, stake, get rewarded
              </div>
              {/* <div className='marketplace-text-container' style={{marginLeft:'auto'}}>
                  Your balance : <img ></img> {alphaBalance}
              </div> */}
            </div>
            <div className='marketplace-banner-img'>
              <div className='opensea-txt'> The gamified ecosystem Powered by $JNGL </div>
              <a href='https://opensea.io/collection/supremekong' target="_blank"> <div className='opensea-btn'> Buy on Opensea </div></a>
            </div>
          </div>
          
          <div style={{display:'flex'}}>
            <div className='marketplace-text-container-bottom-title'>
              Available Rewards
            </div>
            {/* <div className='marketplace-text-container' style={{marginLeft:'auto'}}>
                Your balance : <img ></img> {alphaBalance}
            </div> */}
          </div>
          <div className='marketplace-items-container'>

          { Listing.map( (item) => (
            <div className='marketplace-item-card' onClick={() => handleItemClick(item)}>
              <div className='marketplace-item-card-image'>
                <img src={item.image}></img>
                
              </div>

              <div className='marketplace-item-card-info'>

                <div className='marketplace-item-card-info-title'>
                  <p style={{fontSize:'20px'}}>{item.name}</p> 
                </div>

                <div className='marketplace-item-card-info-price'>
                  <p style={{fontSize:'14px'}}><img src={token2}></img> {item.priceDisplay} $JNGL</p> <p style={{marginLeft:'auto'}}>Supply: {item.supply}</p>
                </div>

                <div className='marketplace-item-card-info-button'>
                    <div className='marketplace-item-card-info-button-buy' onClick={()=>{buy(item.collectionId,item.price,item.amount)}}>
                      Buy
                    </div>
                    <div className='marketplace-item-card-info-button-amount' >
                      {item.amount}
                    </div>
                    <div className='marketplace-item-card-info-button-minus' onClick={()=>{decrement(item)}}>
                      -
                    </div>
                    <div className='marketplace-item-card-info-button-plus' onClick={()=>{increment(item)}}>
                      +
                    </div>


                    <div className='marketplace-item-card-info-button-max' onClick={()=>{buy(item.collectionId,item.price)}}>
                      MAX
                    </div>

                </div>

              </div>
            </div>
          ))}

           
          </div>

        </div>
      </div>
    </div>
    <footer className="footer">
        <div className="footer__section">
          <p className="footer__copyright">Copyright © 2023 $JNGL Token. All Rights Reserved.</p>
        </div>
        <div className="footer__section"> 
          <a href='https://etherscan.io/token/0x38f9bb135ea88033f4377b9ea0fb5cfb773fec2f'><img className="footer__icon" src={etherscan} alt="Icon 1"/></a>
          <a href='https://discord.com/invite/supremekong'><img className="footer__icon" src={discord} alt="Icon 1"/></a>
          <a href='https://twitter.com/Supremekongnft'><img className="footer__icon" src={twitter} alt="Icon 1"/></a>
          <a href='https://opensea.io/collection/supremekong'> <img className="footer__icon" src={opensea} alt="Icon 1"/></a>
          <a href='https://www.dextools.io/app/en/ether/pair-explorer/0x70235a346a1ec1d7a40181ff88a3a2e5260e1d04'> <img className="footer__icon" src={dex} alt="Icon 1"/></a>
          <a href='mailto:cyrus@supremekong.com'> <img className="footer__icon" src={emailIcon} alt="Icon 1"/></a>
        </div>
    </footer>
    </>
  );
}
