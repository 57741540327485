import React from 'react';
import { motion } from 'framer-motion';
import AnimateTextInView from '../lib/SectionTitle/AnimateTextInView';
import { paragraphAnimation } from '../../utils/config';

import token from '../../assets/images/jngl-token.png'

import './GetInTouch.scss';
function GetInTouch() {
  return (
    <section className='GetInTouch'>
      <div className='GetInTouch-title'>
        <AnimateTextInView className='Title' text='COMING SOON' type='h1' />
      </div>
      <div className='GetInTouch-content'>
          <img src={token}>
          </img>
      </div>
    </section>
  );
}

export default GetInTouch;
