import * as React from 'react';
import {useEffect, useState} from 'react';
import Logo from "../../assets/images/jngl-logo.png";
import DiscordButton from '../lib/DiscordButton/DiscordButton';
import TwitterIcon from '../../assets/icons/twitter.svg';
import kongToken from '../../assets/images/kong-token.png'
import jnglToken from '../../assets/images/jngltoken.png'
import token2 from '../../assets/images/token-kong.png'
import InstagramIcon from '../../assets/icons/instagram.svg';
import navigateTo from '../../utils/navigateTo';
import { motion } from 'framer-motion';
import { ethers } from 'ethers'
import closeIcon from '../../assets/icons/close.svg';
import burgerIcon from '../../assets/icons/burger-header.png';
import discordIcon from '../../assets/icons/discord.svg';
import './Header.scss';
import { useWeb3Context } from '../../context'
import axios from 'axios';
import { Tooltip } from 'react-tooltip'
import plus from '../../assets/images/plus.png'

const alphaAbi = require('../../helper/alpha.json')

const menuBtn = {
  open: { rotate: 0 },
  closed: { rotate: 180 },
};

const nav = {
  open: { opacity: 1, x: 0 },
  closed: { opacity: 0, x: '130%' },
};
const Header = ({ siteTitle, updateBalance }) => {
  const tokens = localStorage.getItem('token');
  axios.defaults.headers.common['Authorization'] = `Bearer ${tokens}`;
  axios.defaults.headers.common['Content-Type'] = 'application/json';


  const base_uri = "https://api.supremekong.com";
  const kongTokenAddress = "0x4c45bbec2ff7810ef4a77ad7bd4757c446fe4155"
  const [AlphaBalance,setAlphaBalance] = React.useState("0")
  const [KongBalance,setKongBalance] = React.useState("")
  const [open, setOpen] = React.useState(false);
  const { web3Provider, connect, disconnect, address } = useWeb3Context()
  const [balance,setBalance] = React.useState(0)
  const getBalance = async() => {
    console.log("called 3")
    const res = await axios.get(base_uri + '/getBalance?address='+address,
    {
    headers: {
        api_key: 'R8NQzvVkmt5g2n5BNr6kkfEhD8Jm6KLzLPf5lukrz7zMJjIPb9BDhbpQPOQ4nB4m' //the token is a variable which holds the token
    },withCredentials: true
    })

    const roundedBalanceInEther = res.data.result[0].balance;
    const formattedBalance = roundedBalanceInEther.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits:2});
    setBalance(formattedBalance)
  }

  const refreshBalance = async () => {
    
    if (web3Provider) {
      console.log("called 4")
      loadToken();
      getBalance();
    }
  };

  function onNavigate(e) {
    navigateTo(e);
  }

  function handleOpen() {
    setOpen(!open);
  }

  const [timerId, setTimerId] = useState(null);

  useEffect(() => {
    if (web3Provider) {
      refreshBalance();
      // Call refreshBalance every 10 seconds
      const timer = setInterval(() => {
        refreshBalance();
      }, 10000);

      // Store the timer ID in the state variable
      setTimerId(timer);
    }

    // Clear the timer when the component unmounts or when web3Provider changes
    return () => {
      if (timerId) {
        clearInterval(timerId);
      }
    };
  }, [web3Provider, updateBalance]);

  const loadToken = async() => {
    const signer = web3Provider?.getSigner();
    const contract = new ethers.Contract(kongTokenAddress, alphaAbi, signer);
    const balance = await contract.balanceOf(address);
    
    const balanceInEther = ethers.utils.formatEther(balance);
    const roundedBalanceInEther = Math.abs(balanceInEther);
    const formattedBalance = roundedBalanceInEther.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits:2});
    
    setAlphaBalance(formattedBalance);
  }


  return (
    <header className='HeaderJngl'>
      <div className='HeaderJngl__content'>
        <div style={{display:'flex'}}>
          <a className='HeaderJngl__logo'  href='https://jnglcoin.com/'>
            <img src={Logo} alt=''/>
            
          </a>
          {address &&
            <div className='header-desktop' style={{marginLeft:'40px',display:'flex',marginTop:'auto',marginBottom:'auto',gap:'20px'}}>
              <div  style={{display:'flex'}} data-tooltip-id="my-tooltip" data-tooltip-content="$JNGL">
                <a href='https://app.uniswap.org/swap?inputCurrency=ETH&outputCurrency=0x4c45bbec2ff7810ef4a77ad7bd4757c446fe4155' target='_blank'><img src={plus} style={{width:"40px",height:"40px"}}></img></a>
                <div className='balance-display' style={{fontSize:"20px"}}>{AlphaBalance}</div> <img src={token2} width={40} height={40} ></img>
              </div>
              <div  style={{display:'flex'}} data-tooltip-id="my-tooltip" data-tooltip-content="$KONG">
                <div style={{width:'40px',height:'44px'}}></div>
                <div className='balance-display' style={{fontSize:"20px"}}>{balance}</div> <img src={kongToken} width={40} height={40} ></img> 
              </div>
            </div>
          }
          <Tooltip id="my-tooltip" />
        </div>
        <ul className='HeaderJngl__list'>
          
          {/* <li>
            <a onClick={onNavigate} href='#roadmap'>
              Home
            </a>
          </li>
          <li>
            <a onClick={onNavigate} href='#artists'>
              Junglenomics
            </a>
          </li>
          <li>
            <a href='/staking'>
              Junglemap
            </a>
          </li>
          <li>
            <a href='/marketplace'>
              Claim
            </a>
          </li> */}
        </ul>
        <ul className='HeaderJngl__social'>
          {/* <a
            className='HeaderJngl__social__anchor'
            href='https://twitter.com/Supremekongnft'
            target='_blank'
            rel='noreferrer'
          >
            <img src={TwitterIcon} alt='' />
          </a>
          <a
            className='HeaderJngl__social__anchor'
            href='https://discord.com/invite/supremekong'
            target='_blank'
            rel='noreferrer'
          >
            <img src={discordIcon} alt='' />
          </a> */}
          { !web3Provider &&
            <button className='ConnectJnglButton' onClick={connect}>
              <a className='ConnectJnglButton__anchor'>
                CONNECT WALLET
              </a>
            </button>
          }

          {web3Provider &&
            <p className='p-desktop'>{ address.substring(0,3) + "..."+ address.substring(address.length-3,address.length) }</p>
          }
        </ul>
        <motion.button
          animate={open ? 'open' : 'closed'}
          initial={'closed'}
          variants={menuBtn}
          onClick={handleOpen}
          className='HeaderJngl__menu__btn'
        >
          <img src={open ? closeIcon : burgerIcon} alt='' />
        </motion.button>
      </div>
      <motion.nav
        animate={open ? 'open' : 'closed'}
        initial={'closed'}
        variants={nav}
        onClick={handleOpen}
        className='HeaderJngl__menu__btn'
        className='HeaderJngl__motion__nav'
      >
        <ul className='HeaderJngl__motion__nav-anchors'>
          <li style={{textAlign:"center",fontSize:"20px"}} className='li-mobile'>
              {address &&
                address.substring(0,3) + "..."+ address.substring(address.length-3,address.length)
              }
              
              { address &&
                <div className='header-mobile' style={{marginLeft:'40px',marginTop:'20px',marginBottom:'auto',gap:'20px',display:'none'}}>
                  <div  style={{display:'flex',placeContent:'center'}} data-tooltip-id="my-tooltip" data-tooltip-content="$JNGL">
                    <a href='https://app.uniswap.org/swap?inputCurrency=ETH&outputCurrency=0x4c45bbec2ff7810ef4a77ad7bd4757c446fe4155' target='_blank'><img src={plus} style={{width:"40px",height:"40px"}}></img></a>
                    <div className='balance-display' style={{fontSize:"20px"}}>{AlphaBalance}</div> <img src={token2} width={40} height={40} ></img>
                  </div>
                  <div  style={{display:'flex',placeContent:'center'}} data-tooltip-id="my-tooltip" data-tooltip-content="$KONG">
                    <div style={{width:'40px',height:'44px'}}></div>
                    <div className='balance-display' style={{fontSize:"20px"}}>{balance}</div> <img src={kongToken} width={40} height={40} ></img> 
                  </div>
                </div>
              }
          </li> 
          <li>
            <a href='/'>
              Home
            </a>
          </li>
          <li>
            <a href='https://docs.jnglcoin.com/'>
              Whitepaper
            </a>
          </li>
          <li >
          <a href='https://www.supremekong.com/staking'>
                Stake NFT
              </a>
            </li> 
          <li>
            <a href='https://www.supremekong.com/jngl/staking'>
              Stake $JNGL
            </a>
          </li>
          <li>
          <a href='https://www.supremekong.com/marketplace'>
                $KONG Marketplace
              </a>
            </li>
          <li>
            <a href='https://www.supremekong.com/jngl/marketplace'>
              $JNGL Marketplace
            </a>
          </li>
          <li>
            <a href='https://www.supremekong.com/sk1jnglclaim'>
              SK1 $JNGL Claim
            </a>
          </li>
          <li>
            <a href='https://www.supremekong.com/inventory'>
                Inventory
              </a>
          </li>
          <li>
            <a href='https://www.supremekong.com/check-level'>
              Check Level
              </a>
            </li>
            <li>
            <a href='https://www.supremekong.com/check-jngl'>
              Check $JNGL
              </a>
            </li>
            <li>
            <a href='https://www.supremekong.com/transaction'>
              History
            </a>
          </li>
          <li>
          <a href='https://www.supremekong.com/stats'>
              Stats
            </a>
          </li>
          <li>
          <a href='https://www.supremekong.com/faq'>
              FAQ
            </a>
          </li>
          {/* <li>
            <a href='/jungle/raffle'>
              Raffle
            </a>
          </li> */}
        </ul>
        {/* <ul className='HeaderJngl__social'>
          <a
            className='HeaderJngl__social__anchor'
            href='https://twitter.com/Supremekongnft'
            target='_blank'
            rel='noreferrer'
          >
            <img src={TwitterIcon} alt='' />
          </a>
          <DiscordButton href='https://discord.com/invite/supremekong' text='JOIN OUR DISCORD' blank />
        </ul> */}
      </motion.nav>
    </header>
  );
};

export default Header;



