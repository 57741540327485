import * as React from "react"

import './Mutate.scss';
import DiscordButton from '../../components/lib/DiscordButton/DiscordButton';
import Preloader from '../../components/Preloader/Preloader';
import Header from '../../components/HeaderWallet/HeaderWallet';
import { useWeb3Context } from '../../context'
import { useNavigate, useParams } from "react-router-dom";
import { ethers } from 'ethers'
import { toast } from 'react-toastify';
import axios from "axios";
import mutantbanana from '../../assets/images/mutantbanana.png'
import arrow from '../../assets/images/arrow.png'
import ProgressBar from "../../components/Progressbar/Progressbars";
import { fail } from "assert";
import { HmacSHA256, enc } from 'crypto-js';
const spk = require('../../helper/spk.json')

const mutantbananaAbi = require('../../helper/mutantBanana.json')
const stakingAbi = require('../../helper/stakeAbi.json')
const bananaAbi = require('../../helper/bananaAbi.json')

let interval;

function Mint() {
    axios.defaults.withCredentials = true;
    let navigate = useNavigate()

    let { id } = useParams();

    //const base_uri = "http://localhost:8001";
    const base_uri = "https://api.supremekong.com";


    const bananaContract = process.env.REACT_APP_BANANA_CONTRACT;
    const mutantBananaContract = process.env.REACT_APP_MUTANT_BANANA_CONTRACT;
    const stakingContract = process.env.REACT_APP_STAKING_CONTRACT;
    const kong2Contract = process.env.REACT_APP_KONG2_CONTRACT;

    const burnCost = 5;

    const arrayCosts = [1,2,3,5,6,8,10,12,14,18]

    const arrayBonus = ["0%","2%","4%","6%","8%","10%","15%","25%","40%","65%","100%"]

    const { web3Provider, connect, disconnect, address } = useWeb3Context()
    
    //let address = "0x800777FeCa205029D507982E98139ae219F5fE22"

    const [loaded, setLoaded] = React.useState(false)

    const [owner, setOwner] = React.useState(false)

    const [potion, setPotion] = React.useState(0)

    const [load, setLoad] = React.useState(false)

    const [level, setlevel] = React.useState(0)

    const [chance, setChance] = React.useState(0)

    const [eligible, setEligible] = React.useState(true)

    const [maxAmount, setMaxAmount] = React.useState(0)

    const [amount, setAmount] = React.useState(0);

    const [approvedBanana, setApprovedBanana] = React.useState(false)

    const [mintVisible, setMintVisible] = React.useState(true);

    const countDownDate = new Date("2022-08-31T22:00:00.000-04:00").getTime();
    const [showModal, setShowModal] = React.useState(true);

    const [countDown, setCountDown] = React.useState(
        countDownDate - new Date().getTime()
    );

    const [bananaNft, setbananaNft] = React.useState([])

    const [leveling, setLeveling] = React.useState(false)

    const [failed, setFailed] = React.useState(false)

    const [success, setSuccess] = React.useState(false)

    const [progress, setProgress] = React.useState(false)

    const [progressRate, setProgressRate] = React.useState(0)

    const [day,setDay] = React.useState(0)
    const [hour,setHour] = React.useState(0)
    const [minute,setMinute] = React.useState(0)
    const [second,setSecond] = React.useState(0)


    const handleLoad = React.useMemo(() => {
        setLoaded(true)
    }, []);

    React.useEffect(async() => {
        //Runs only on the first render
        // if(web3Provider){
        //     loadBananaNft();
        // }else{
        //     navigate('/connect')
        // }
        loadBananaNft()
        //setOwner(true)
        getMetadata()
    }, [web3Provider])

    React.useEffect(async() => {
        if (progressRate === 100) {
            clearInterval(interval);
            let now = Math.floor(Date.now() / 1000)
            const body = {
                address: address,
                id: id,
                timestamp: now
            }
            const hashkey = enc.Hex.stringify(HmacSHA256(JSON.stringify(body),process.env.REACT_APP_SECRET_KEY))

            const res = await axios.post(base_uri + '/levelUpGen2',
            body,
            {
            headers: {
                api_key: 'R8NQzvVkmt5g2n5BNr6kkfEhD8Jm6KLzLPf5lukrz7zMJjIPb9BDhbpQPOQ4nB4m', //the token is a variable which holds the token
                hash_key: hashkey
            }
            })
            if(res.data.msg == "Success level up"){
                await getMetadata()
                setProgress(false)
                setSuccess(true)
            }else{
                await getMetadata()
                setProgress(false)
                setFailed(true)
            }
        }
        console.log("progressrate",progressRate)
    }, [progressRate]);
    

    const loadBananaNft = async() => {
        const signer = web3Provider?.getSigner();
        // const stakingcontract = new ethers.Contract(stakingContract, stakingAbi, signer);
        console.log(spk)
        const stakeContract = new ethers.Contract(kong2Contract, spk, signer);
        const ownerAddress = await stakeContract.ownerOf(id)
        console.log("owner",ownerAddress)
        if(address.toLowerCase() == ownerAddress.toLowerCase()){
            setOwner(true)
        }else{
            const ownerToken = await stakeContract.stakedNfts(address)

            ownerToken.map(
                (num) => {
                    if(num.toNumber() == id){
                        console.log("same",num.toNumber())
                        setOwner(true)
                    }
                }
            )
        }
    }

    const getMetadata = async() => {
        const res = await axios.get(base_uri+'/getLevelGen2s3?id='+id,
        {
        headers: {
            api_key: 'R8NQzvVkmt5g2n5BNr6kkfEhD8Jm6KLzLPf5lukrz7zMJjIPb9BDhbpQPOQ4nB4m' //the token is a variable which holds the token
        }
        })
        console.log("res",res.data)
        let metadata = res.data
        let levels = metadata.level
        let chances = arrayCosts[levels]
        const resp = await axios.get(base_uri+'/getClaimedPrize?address='+ address +'&id='+2,
        {
        headers: {
            api_key: 'R8NQzvVkmt5g2n5BNr6kkfEhD8Jm6KLzLPf5lukrz7zMJjIPb9BDhbpQPOQ4nB4m' //the token is a variable which holds the token
        }
        })
        let potions = resp.data.claimed
        console.log(resp.data)
        setPotion(resp.data.claimed)
        setlevel(levels)
        setChance(chances)
        console.log(chances)
        console.log(potions)
        if(chances > potions){
            console.log("chance")
            setEligible(false)
        }else{
            setEligible(true)
        }
    }

    const levelUp = async() => {
        
        const _id = toast.loading("Leveling in progress")
        try{
            let now = Math.floor(Date.now() / 1000)
            const body = {
                address: address,
                id: id,
                timestamp: now
            }
            const hashkey = enc.Hex.stringify(HmacSHA256(JSON.stringify(body),process.env.REACT_APP_SECRET_KEY))

            const res = await axios.post(base_uri + '/levelUpGen2',
            body,
            {
            headers: {
                api_key: 'R8NQzvVkmt5g2n5BNr6kkfEhD8Jm6KLzLPf5lukrz7zMJjIPb9BDhbpQPOQ4nB4m', //the token is a variable which holds the token
                hash_key: hashkey
            }
            })
            
            if(res.data.msg == "Success level up"){
                toast.update(_id, {render: res.data.msg, type: "success", autoClose: 1000, isLoading:false});
                getMetadata();
                var element = document.getElementById("videobackground");
                element.classList.add("videogradient");
                setTimeout(() => {
                    element.classList.remove("videogradient")
                }, 5000);
            }else{
                toast.update(_id, {render: res.data.msg, type: "error", autoClose: 1000, isLoading:false});
                getMetadata();
                var element = document.getElementById("videobackground");
                element.classList.add("videofail");
                setTimeout(() => {
                    element.classList.remove("videofail")
                }, 5000);
            }

        }catch(err) {
            toast.update(_id, {render: "failure", type: "error", autoClose: 1000, isLoading:false});
        }
    }

    const levelingStart = async() =>{
        setProgressRate(0)
        setLeveling(true)
        setProgress(true)
        interval = setInterval(() => {
            setProgressRate((prev) => prev + 20);
        }, 500);
        
        //setSuccess(true)
        //setFailed(true)
    }

    const batchMutate = async() => {
        
        if(amount > 0){
            const signer = web3Provider?.getSigner();
            const contract = new ethers.Contract(mutantBananaContract, mutantbananaAbi, signer);


            let tempArr = bananaNft
            let newArr = Array()
            for(let i = 0; i < (amount*5); i++){
                newArr.push(tempArr[i])
            }
            let tempArray = bananaNft;
            console.log(tempArray)
            try{
                const tx = await contract.batchMutate(address,newArr)

                let receipt = await toast.promise(
                tx.wait(),
                {
                    pending: 'Mutation in Progress',
                    success: 'Mutation Success',
                    error: 'Mutation Failed'
                })

                loadBananaNft()
                setAmount(0)
                //toast.success("Mutation Success")
            }catch(err){
                console.log(err)
                toast.error("Mutation Failed")
            }
        }else{
            toast.error("Amount must be bigger than 0")
        }
    }

    const bananaApproveAll = async() => {
        const signer = web3Provider?.getSigner();
        const contract = new ethers.Contract(bananaContract, bananaAbi, signer);
        try{
            const tx = await contract.setApprovalForAll(mutantBananaContract, true)
            let receipt = await toast.promise(
                tx.wait(),
                {
                    pending: 'Validation in Progress',
                    success: 'Validation Success',
                    error: 'Validation Failed'
                })
            const approval = await contract.isApprovedForAll(address,mutantBananaContract)

            setApprovedBanana(approval)
        }catch(err){
            console.log(err)
            toast.success("Approval Failed")
        }
    }


    return (
        <>
        <Preloader handleLoad={handleLoad} />
        <Header siteTitle={`Title`} />
        <div className='Mint__content'>
        
        {/* <motion.h1 
        {...paragraphAnimation}
        className='Mint__content__title Title'>THE SUPREME LEADERS OF THE METAVERSE</motion.h1> */}
    { !leveling &&
    <>
        <div className='level-container'>
            <p>UPGRADE NFT</p>
            <p style={{fontSize:"14px",marginTop:"15px"}}>Supreme Kong 2 #{id}</p>
        
        <div className="level-change">
            { eligible &&
            <div className="level-before">
                {/* <video autoPlay loop playsInline controls muted src="https://assets.supremekong.com/mutant-bananas/images/mutant-banana.mp4" ></video>
                 */}
                 <img src={"https://gen2.supremekong.com/images/"+id+".jpg"}></img>
                Level {level}
            </div>
            }
            { !eligible &&
            <div className="level-failed">
                {/* <video autoPlay loop playsInline controls muted src="https://assets.supremekong.com/mutant-bananas/images/mutant-banana.mp4" ></video> */}
                <img src={"https://gen2.supremekong.com/images/"+id+".jpg"}></img>
                Level {level}
            </div>
            }
        </div>
        { eligible &&
            <div className="level-cost">
                <p>costs</p>
                <img src="https://assets.supremekong.com/images/potion.png"></img>
                <p>{potion}/{chance}</p>
            </div>
        }

        { !eligible &&
            <div className="level-cost-red">
                <p>costs</p>
                <img src="https://assets.supremekong.com/images/potion.png"></img>
                <p>{potion}/{chance}</p>
            </div>
        }
        <div className="level-button">
            { (owner && eligible) &&
                <div className='mintBtn' onClick={levelingStart}>
                    LEVEL UP
                </div>
            }

            { (!owner || !eligible) &&
                <div className='disableBtn'>
                    LEVEL UP
                </div>
            }
        </div>
        </div>
    </>
    }
    { leveling &&
    <>
        <div className='leveling-up-img'>
         { success &&
            <div className="level-border-success">
                 <img src={"https://gen2.supremekong.com/images/"+id+".jpg"}></img>
                <p>Level {level}</p>
            </div>
        }
        { failed &&
            <div className="level-border-fail">
                 <img src={"https://gen2.supremekong.com/images/"+id+".jpg"}></img>
                <p>Level {level}</p>
            </div>
        }
        { progress &&
            <div className="">
                 <img src={"https://gen2.supremekong.com/images/"+id+".jpg"}></img>
                <p>Level {level}</p>
            </div>
        }
        </div>
        <div className="leveling-up-txt">
        { progress &&
        <>
           LEVELING UP
        </>
        }
        { success &&
        <>
           <p style={{color:"#F2B90C"}}>
               LEVELING UP SUCCESS !
           </p>

           <p className="description">
               Your Supreme Kong 2 #{id} have been leveled up to Level {level}.
           </p>
           <p style={{color:"#F2B90C",fontSize:"20px"}}>
               Your yield bonus has increased to +{arrayBonus[level]} !
           </p>
        </>
        }
        { failed &&
        <>
           <p style={{color:"#C20000"}}>
               LEVELING UP FAILED !
           </p>

           <p className="description">
               Your Supreme Kong 2 #{id} have failed to level up.
           </p>
           <p style={{color:"#C20000",fontSize:"20px"}}>
               Your yield bonus has decreased to +{arrayBonus[level]} !
           </p>
        </>
        }
        </div>
        <div className="leveling-up-progress">
        { progress &&
            <ProgressBar bgcolor={"#F2B90C"} completed={progressRate} />
        }
        { ( success || failed ) &&    
            <div className='cancelBtn' onClick={()=>{
                setSuccess(false)
                setFailed(false)
                setLeveling(false)
            }}>
                CLOSE
            </div>
        }
        </div>
    </>
    }
      </div>
        </>
    );
}

export default Mint;
