const BuyJnglCard = ({ JnglData }) => {
  return (
    <section id="ownjngl" class="secpad">
      <div class="stars2"></div>
      <div class="container">
        <div class="row">
          <div class="col-lg-7">
            <h2 data-cue="slideInLeft">Buy $JNGL</h2>
            <h3 class="mt-2" data-cue="slideInDown">
              The peoples token, the opportunity to back industry plays, and own
              a piece of the jungle instead of VC's.
            </h3>
            <img
              src="/assets/images/jnglpage/own-mcp.png"
              class="d-none"
              alt="cp"
            />
            <p class="mt-5" data-cue="slideInUp">
              Owning $JNGL gives you full access into all the benefits Jungle
              Labz offers its holdership. Buy or trade JNGL on our most trusted
              DEXs:
            </p>
            <div class="own-tools py-4">
              <img
                src="/assets/images/jnglpage/own-dextools.png"
                data-cue="slideInUp"
                alt="DexTools"
                class="img-fluid"
              />{" "}
              <img
                src="/assets/images/jnglpage/own-uniswap.png"
                data-cue="slideInDown"
                alt="Uniswap"
                class="img-fluid ms-5"
              />
            </div>
            <a
              href="https://app.uniswap.org/explore/tokens/ethereum/0x4c45bbec2ff7810ef4a77ad7bd4757c446fe4155"
              class="btn btn-primary mt-lg-4"
            >
              Buy Here
            </a>{" "}
            <a
              href="https://www.supremekong.com/jngl/staking"
              class="btn btn-outline-white ms-lg-4 mt-lg-4 mt-sm-2 stakejngl"
            >
              Stake $JNGL <i class="bi bi-arrow-right"></i>
            </a>
          </div>
        </div>
        <div class="row mt-6">
          <div class="col-lg-4 col-sm-6 col-12">
            <div class="own-box mb-3" data-cue="slideInLeft">
              <div class="shadow out"></div>
              <p data-cue="slideInDown">Market Cap</p>
              <h1 className="jnglfont">
                {JnglData && JnglData.fully_diluted_market_cap
                  ? JnglData.fully_diluted_market_cap
                  : "4,161,408"}
              </h1>
            </div>
          </div>
          <div class="col-lg-4 col-sm-6 col-12">
            <div class="own-box mb-3" data-cue="slideInUp">
              <div class="shadow out"></div>
              <p data-cue="slideInDown">Circulating Supply</p>
              <h1 className="jnglfont">9,700,000</h1>
            </div>
          </div>
          <div class="col-lg-4 col-sm-12 col-12">
            <div class="own-box mb-3" data-cue="slideInRight">
              <div class="shadow out"></div>
              <p data-cue="slideInDown">Total Supply</p>
              <h1 className="jnglfont">9,700,000</h1>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BuyJnglCard;
