import * as React from "react"
import './Staking.scss';
import { ethers } from 'ethers'
import DiscordButton from '../../components/lib/DiscordButton/DiscordButton';
import Preloader from '../../components/Preloader/Preloader';
import Header from '../../components/HeaderJngl/Header';
import ProgressBar from "../../components/Progressbar/Progressbar";
import { useWeb3Context } from '../../context'
import { toast } from 'react-toastify';
import mutantbanana from '../../assets/images/supreme-bananas.jpeg'
import suprememutantbanana from '../../assets/images/mutant-bananas.png'
import spkImg from '../../assets/images/spk.png'
import { useNavigate } from "react-router-dom";
import { RingSpinnerOverlay } from 'react-spinner-overlay'
import { HmacSHA256, enc } from 'crypto-js';
import {
    Web3ProviderState,
    Web3Action,
    web3InitialState,
    web3Reducer,
  } from '../../reducers'
  import {
    Accordion,
    AccordionItem,
    AccordionItemButton,
    AccordionItemHeading,
    AccordionItemPanel,
    AccordionItemState
  } from 'react-accessible-accordion';
  import 'react-accessible-accordion/dist/fancy-example.css';

import lock from '../../assets/images/lock.png'
import unlock from '../../assets/images/open-lock.png'
import axios from "axios";
import web3 from "web3";

const spk = require('../../helper/spk.json')
const kongAbi = require('../../helper/kongAbi.json')
const stakeAbi = require('../../helper/stakeAbi.json')
const bananaAbi = require('../../helper/bananaAbi.json')


function Staking() {
    axios.defaults.withCredentials = true;
    let navigate = useNavigate()

    const kongContract = "0x19761CB1f7b4A489634ea9c9360587F6f8D87A32";
    const kong2Contract = process.env.REACT_APP_KONG2_CONTRACT;
    const bananaContract = process.env.REACT_APP_BANANA_CONTRACT;
    const mutantBananaContract = process.env.REACT_APP_MUTANT_BANANA_CONTRACT;
    const stakingContract = "0x6D2ca1920eCbAf404C6150D44Ac568A7046986A7";
    const base_uri = "https://api.supremekong.com";
    //const base_uri = "http://localhost:8001";
    const [totalAcrueReward, setTotalAcrueReward] = React.useState(0)
    
    const kong_index = 3;
    const banana_index = 4;
    const mutant_index = 5;

    const array_tier = [7,14,30,90,365]

    const array_tier_bonus = ["0%","3%","5%","7%","10%","15%"]

    const tier_bonus_number = [0,0.03,0.05,0.07,0.1,0.15]

    const array_mutant_level_bonus = ["0%","2%","4%","6%","8%","10%","15%","25%","40%","65%","100%"]

    const mutant_level_bonus_number = [0,0.02,0.04,0.06,0.08,0.1,0.15,0.25,0.4,0.65,1]

    const array_gen2_level_bonus = ["0%","2%","4%","6%","8%","10%","15%","25%","40%","65%","100%"]

    const gen2_level_bonus_number = [0,0.02,0.04,0.06,0.08,0.1,0.15,0.25,0.4,0.65,1]

    const bananaRewardRate = 0.003234375;
    const kongRewardRate = 0.015;
    const mutantRewardRate= 0.0163875;
    const bonusSkSb = 0.001078125
    const bonusSkMb = 0.0025875

    const kongPid = 0;
    const bananaPid = 1;
    const mutantPid = 2;

    const { web3Provider, connect, disconnect, address } = useWeb3Context()


    //let address = "0x566393DaCad7DAE6e94b70484441B2762F371A0B"
    //let address = "0x54Fec6309b53A31e65593f196b4c58f7A704A361"
    //let address = "0x17df9c6a06043a7d2390e8eb6f9eab63edffa140"
    //let address = "0x21e1650c1c0255884579713e0c651c8f33d6ac5cƒsupreme"
    //let address = "0x720b38453aD04296e55642f6dbF303B630C37a65"

    const [state, dispatch] = React.useReducer(web3Reducer, web3InitialState)

    const [loaded, setLoaded] = React.useState(false)

    const [loading, setLoading] = React.useState(false)
    
    const [timeNow, setTimeNow] = React.useState(0)

    const [loadCount, setLoadCount] = React.useState(0)

    const [stakedCount, setStakedCount] = React.useState(0)

    const [count, setCount] = React.useState(0)

    const [balances, setBalances] = React.useState(0)

    const [kongNft, setkongNft] = React.useState([])

    const [kongGen2Nft, setkongGen2Nft] = React.useState([])

    const [kongGen2Nfts, setkongGen2Nfts] = React.useState([])

    const [stakedKongNft, setStakedKongNft] = React.useState([])

    const [stakedKongNfts, setStakedKongNfts] = React.useState([])

    const [stakedKongGen2Nft, setStakedKongGen2Nft] = React.useState([])

    const [stakedKongGen2Nfts, setStakedKongGen2Nfts] = React.useState([])

    const [bananaNft, setbananaNft] = React.useState([])

    const [stakedBananaNft, setStakedBananaNft] = React.useState([])

    const [stakedBananaNfts, setStakedBananaNfts] = React.useState([])

    const [mutantBananaNft, setmutantBananaNft] = React.useState([])


    const [mutantBananaNfts, setmutantBananaNfts] = React.useState([])

    const [stakedMutantBananaNft, setStakedMutantBananaNft] = React.useState([])

    const [stakedMutantBananaNfts, setStakedMutantBananaNfts] = React.useState([])

    const [approvedKong, setApprovedKong] = React.useState(false)

    const [approvedKongGen2, setApprovedKongGen2] = React.useState(false)

    const [approvedBanana, setApprovedBanana] = React.useState(false)

    const [disableClaimKong, setDisableClaimKong] = React.useState(false)

    const [disableClaimBanana, setDisableClaimBanana] = React.useState(false)

    const [disableClaimMutant, setDisableClaimMutant] = React.useState(false)

    const [disableClaimKongGen2, setDisableClaimKongGen2] = React.useState(false)

    const [approvedMutantBanana, setApprovedMutantBanana] = React.useState(false)

    const [dailyYield, setDailyYield] = React.useState(0)

    const [accordionToogle1, setAccordionToogle1] = React.useState(false)

    const handleLoad = React.useMemo(() => {
        setLoaded(true)
    }, []);

    React.useEffect(async() => {
        //Runs only on the first render
        console.log("loadcount",loadCount)
        if(loadCount >= 6){
            setLoading(false)
        }
    }, [loadCount])

    React.useEffect(async() => {
        //Runs only on the first render
        if(web3Provider){
            setLoading(true)
            const interval = setInterval(() => {
                let now = Math.floor(Date.now() / 1000);
                setTimeNow(now)
            }, 2000);
    
            try {
                await Promise.all([
                    loadKongNft(),
                    loadStakedKong(),
                ]);
            } catch (err) {
                console.error("An error occurred while loading data:", err);
                setLoading(false)
            }
            
            setLoading(false)
        }
    
        if(web3Provider == null){
            setTimeout( () => {
                setCount(count+1);
            },200)
        }
    
    }, [web3Provider,count])


    const getAcrueReward = async() => {
        let totalReward = 0;
        for (let i = 0; i < stakedKongNft.length; i++) {
            const object = stakedKongNft[i];
            let bonusReward = 0;
            if(object.bonus){
                bonusReward = object.bonusRate * 60 * 60 * 24;
            }
            const kongReward = kongRewardRate * 60 * 60 * 24;
            const bonusTier = getTierBonus(object.stakedTime) * kongReward;
            totalReward += parseFloat(bonusTier);
            totalReward += parseFloat(bonusReward);
            totalReward += parseFloat(kongReward);
        }

        console.log("total reward 1", totalReward)

        for (let i = 0; i < stakedBananaNft.length; i++) {
            const object = stakedBananaNft[i];
            let bonusReward = 0;
            if(object.bonus){
                bonusReward = object.bonusRate * 60 * 60 * 24;
            }
            const kongReward = bananaRewardRate * 60 * 60 * 24;
            const bonusTier = getTierBonus(object.stakedTime) * bananaRewardRate;
            totalReward += parseFloat(bonusTier);
            totalReward += parseFloat(bonusReward);
            totalReward += parseFloat(kongReward);
        }

        console.log("total reward 2", totalReward)

        for (let i = 0; i < stakedMutantBananaNft.length; i++) {
            const object = stakedMutantBananaNft[i];
            let bonusReward = 0;
            if(object.bonus){
                bonusReward = object.bonusRate * 60 * 60 * 24;
            }
            const kongReward = mutantRewardRate * 60 * 60 * 24;
            const bonusTier = getTierBonus(object.stakedTime) * mutantRewardRate;
            const levelBonus = kongReward * mutant_level_bonus_number[object.level];
            totalReward += parseFloat(bonusTier);
            totalReward += parseFloat(bonusReward);
            totalReward += parseFloat(kongReward);
            totalReward += parseFloat(levelBonus);
        }

        console.log("total reward 3", totalReward)

        for (let i = 0; i < stakedKongGen2Nft.length; i++) {
            const object = stakedKongGen2Nft[i];

            console.log("object gen 2", object)
            let bonusReward = 0;
            if(object.bonus){
                bonusReward = object.bonusRate * 60 * 60 * 24;
            }
            const kongReward = (kongRewardRate/5) * 60 * 60 * 24;
            const bonusTier = getTierBonus(object.stakedTime) * (kongRewardRate/5);
            const levelBonus = kongReward * gen2_level_bonus_number[object.level];
            totalReward += parseFloat(bonusTier);
            totalReward += parseFloat(bonusReward);
            totalReward += parseFloat(kongReward);
            totalReward += parseFloat(levelBonus);
        }

        console.log("total reward 4", totalReward)

        setTotalAcrueReward(totalReward)
    }

    const getYield = async() => {
        const res = await axios.get(base_uri + '/getDailyYield?address='+address,
        {
        headers: {
            api_key: 'R8NQzvVkmt5g2n5BNr6kkfEhD8Jm6KLzLPf5lukrz7zMJjIPb9BDhbpQPOQ4nB4m' //the token is a variable which holds the token
        }
        })
        console.log("yield",res)
        setDailyYield(res.data.yield)
      }

    const getBalance = async() => {
        console.log(address)
        const res = await axios.get(base_uri + '/getBalance?address='+address,
        {
        headers: {
            api_key: 'R8NQzvVkmt5g2n5BNr6kkfEhD8Jm6KLzLPf5lukrz7zMJjIPb9BDhbpQPOQ4nB4m' //the token is a variable which holds the token
        }
        })
        setBalances(res.data.result[0].balance)
    }


    React.useEffect(async() => {
        //Runs only on the first render
            console.log("bonus")
            console.log("bonus",stakedMutantBananaNft)
            console.log("bonus",stakedKongNft)
            console.log("bonus",stakedKongGen2Nft)
            let tempStakedBanana = stakedBananaNft
            let tempStakedMutant = stakedMutantBananaNft
            let tempStakedKong = stakedKongNft
            let tempStakedKongGen2 = stakedKongGen2Nft
            tempStakedBanana = tempStakedBanana.sort((a, b) => {
                if(a.stakedTime === b.stakedTime){
                    return a.tokenId < b.tokenId ? -1 : 1
                } else {
                    return a.stakedTime < b.stakedTime ? -1 : 1
                }
            })
            tempStakedKong = tempStakedKong.sort((a, b) => {
                if(a.stakedTime === b.stakedTime){
                    return a.tokenId < b.tokenId ? -1 : 1
                } else {
                    return a.stakedTime < b.stakedTime ? -1 : 1
                }
            })
            tempStakedKongGen2 = tempStakedKongGen2.sort((a, b) => {
                if(a.stakedTime === b.stakedTime){
                    return a.tokenId < b.tokenId ? -1 : 1
                } else {
                    return a.stakedTime < b.stakedTime ? -1 : 1
                }
            })
            tempStakedMutant = tempStakedMutant.sort((a, b) => {
                if(a.stakedTime === b.stakedTime){
                    return a.tokenId < b.tokenId ? -1 : 1
                } else {
                    return a.stakedTime < b.stakedTime ? -1 : 1
                }
            })
            let mindex = 0
            let bindex = 0
            for(let i =0; i < tempStakedKong.length; i++){
                console.log("here")
                if(mindex < tempStakedMutant.length){
                    tempStakedKong[i].bonus = 1;
                    let mul = 1/2;
                    tempStakedKong[i].bonusRate = (bonusSkMb * mul)
                    

                    tempStakedMutant[mindex].bonus = 1;
                    tempStakedMutant[mindex].bonusRate = (bonusSkMb * mul)
                    mindex++;
                }else if(bindex < tempStakedBanana.length){
                    
                    tempStakedKong[i].bonus = 1;
                    let mul = 5/6;
                    tempStakedKong[i].bonusRate = (bonusSkSb * mul)

                    tempStakedBanana[bindex].bonus = 1;
                    mul = 1/6;
                    tempStakedBanana[bindex].bonusRate = (bonusSkSb * mul)
                    bindex++;
                }
            }

            for(let i =0; i < tempStakedKongGen2.length; i++){
                console.log("here kong 2")
                if(mindex < tempStakedMutant.length){
                    tempStakedKongGen2[i].bonus = 1;
                    let mul = 1/2;
                    tempStakedKongGen2[i].bonusRate = ((bonusSkMb/5) * mul)
                    

                    tempStakedMutant[mindex].bonus = 1;
                    tempStakedMutant[mindex].bonusRate = ((bonusSkMb/5) * mul)
                    mindex++;
                }else if(bindex < tempStakedBanana.length){
                    
                    tempStakedKongGen2[i].bonus = 1;
                    let mul = 5/6;
                    tempStakedKongGen2[i].bonusRate = ((bonusSkSb/5) * mul)

                    tempStakedBanana[bindex].bonus = 1;
                    mul = 1/6;
                    tempStakedBanana[bindex].bonusRate = ((bonusSkSb/5) * mul)
                    bindex++;
                }
            }
            tempStakedBanana = tempStakedBanana.sort((a, b) => (a.tokenId > b.tokenId) ? 1 : -1)
            tempStakedKong = tempStakedKong.sort((a, b) => (a.tokenId > b.tokenId) ? 1 : -1)
            tempStakedMutant = tempStakedMutant.sort((a, b) => (a.tokenId > b.tokenId) ? 1 : -1)
            tempStakedKongGen2 = tempStakedKongGen2.sort((a, b) => (a.tokenId > b.tokenId) ? 1 : -1)

            setStakedBananaNft(tempStakedBanana)
            setStakedKongNft(tempStakedKong)
            setStakedMutantBananaNft(tempStakedMutant)
            setStakedKongGen2Nft(tempStakedKongGen2)
            getAcrueReward()
        
    }, [stakedMutantBananaNft,stakedKongNft,stakedBananaNft,stakedKongGen2Nft])


    

    //mutant

    const loadKongNft = async() => {
        const signer = web3Provider?.getSigner();
        const contract = new ethers.Contract(kongContract, kongAbi, signer);
        const totalSupply = await contract.balanceOf(address);
        const totalNft = totalSupply.toNumber();
        console.log(totalNft);
    
        let tempArr = Array();
        let promises = Array();
    
        for(let i = 0; i < totalNft; i++){
            promises.push(contract.tokenOfOwnerByIndex(address,i));
        }
    
        const nfts = await Promise.all(promises);
    
        for(let i = 0; i < totalNft; i++){
            tempArr.push(nfts[i].toNumber());
        }
    
        setkongNft(tempArr);
    
        const approval = await contract.isApprovedForAll(address,stakingContract);
        setApprovedKong(approval);
    };

    const kongApproveAll = async(event) => {
        event.stopPropagation();
        const signer = web3Provider?.getSigner();
        const contract = new ethers.Contract(kongContract, kongAbi, signer);
        try{
            const tx = await contract.setApprovalForAll(stakingContract, true)
            let receipt = await toast.promise(
            tx.wait(),
            {
                pending: 'Validate in Progress',
                success: 'Validate Success',
                error: 'Validate Failed'
            })

            const approval = await contract.isApprovedForAll(address,stakingContract)
            setApprovedKong(approval)
        }catch(err){
            console.log(err)
            toast.success("Validate Failed")
        }
    }


    const loadStakedKong = async () => {
        try {
            const signer = web3Provider?.getSigner();
            const contract = new ethers.Contract(stakingContract, stakeAbi, signer);
    
            const stakedKong = await contract.stakedNfts(address, kongPid);
            console.log("staked kong", stakedKong);
    
            const tokenIds = [];
            const stakedTimes = [];

    
            for (const _stakedTokenId of stakedKong) {
                try {
                    const tokenId = _stakedTokenId.toNumber();
                    const timestamp = await contract.stakedNftInfo(tokenId, kongPid);
                    const stakedTime = timestamp[1].toNumber();
    
                    tokenIds.push(tokenId);
                    stakedTimes.push(stakedTime);
                } catch (innerErr) {
                    console.error("Error processing staked kong token:", _stakedTokenId.toString(), innerErr);
                }
            }

            if(stakedKong.length == 0){
                return
            }
    
            // const res = await axios.get(base_uri + '/getStakedV2', {
            //     params: {
            //         address,
            //         poolId: kongPid,
            //         tokenIds,
            //         stakedTimes,
            //     },
            //     headers: {
            //         api_key: 'R8NQzvVkmt5g2n5BNr6kkfEhD8Jm6KLzLPf5lukrz7zMJjIPb9BDhbpQPOQ4nB4m',
            //     },
            // });
    
            // const tempStakedKong = res.data.results.map((result,index) => {
            //     const obj = {
            //         tokenId: tokenIds[index],
            //         claimTime: result.claimTime,
            //         lockTime: stakedTimes[index] + 604800,  // Assuming the lockTime is the same as the stakeTime
            //         stakedTime: stakedTimes[index],
            //     };
            //     return obj;
            // });
    
            // const stakedKongNumbers = stakedKong.map((stakedId) => stakedId.toNumber());
            // const sortedTempStakedKong = tempStakedKong.sort((a, b) => (a.tokenId > b.tokenId) ? 1 : -1);
    
            // setStakedKongNfts(stakedKongNumbers);
            // setStakedKongNft(sortedTempStakedKong);
        } catch (err) {
            console.error("Error loading staked kong:", err);
        }
    };

    const stakeKong = async(tokenId) => {
        const signer = web3Provider?.getSigner();
        const contract = new ethers.Contract(stakingContract, stakeAbi, signer);
        try{
            const tx = await contract.stakeNFT(tokenId,kongPid)
            let receipt = await toast.promise(
            tx.wait(),
            {
                pending: 'Staking in Progress',
                success: 'Staking Success',
                error: 'Staking Failed'
            })

            let arr = Array()
            arr.push(tokenId);
            await batchStaking(kongPid,arr)

            loadKongNft()
            loadStakedKong()
        }catch(err){
            console.log(err)
            toast.success("Staking Failed")
        }
    }

    const batchStakeKong = async(event) => {
        event.stopPropagation();
        const signer = web3Provider?.getSigner();
        const contract = new ethers.Contract(stakingContract, stakeAbi, signer);
        try{
            const tx = await contract.batchStakeNFT(kongNft,kongPid)
            let receipt = await toast.promise(
                tx.wait(),
                {
                    pending: 'Staking in Progress',
                    success: 'Staking Success',
                    error: 'Staking Failed'
                })
            await batchStaking(0,kongNft)
            loadKongNft()
            loadStakedKong()
        }catch(err){
            console.log(err)
            toast.success("Staking Failed")
        }
    }

    const unstakeKong = async(tokenId) => {
        const signer = web3Provider?.getSigner();
        const contract = new ethers.Contract(stakingContract, stakeAbi, signer);
        try{
            const tx = await contract.unstakeNFT(tokenId,kongPid)
            let receipt = await toast.promise(
                tx.wait(),
                {
                    pending: 'Unstaking in Progress',
                    success: 'Unstaking Success',
                    error: 'Unstaking Failed'
                })
            let arr = Array()
            arr.push(tokenId)
            await batchUnstake(kongPid,arr)
            await addReward(kongPid,tokenId)
            loadKongNft()
            loadStakedKong()
            toast.success("unstake Success")
        }catch(err){
            console.log(err)
            toast.success("unstake Failed")
        }
    }


    const batchStaking = async(pid,tokenArray) => {
        let now = Math.floor(Date.now() / 1000)
        const body = {
            address: address,
            poolId: pid,
            tokenId: tokenArray,
            timestamp: now
        }
        const hashkey = enc.Hex.stringify(HmacSHA256(JSON.stringify(body),process.env.REACT_APP_SECRET_KEY))

        const rewards = await axios.post(base_uri + '/BatchStaking', body
        , 
        {
        headers: {
            api_key: 'R8NQzvVkmt5g2n5BNr6kkfEhD8Jm6KLzLPf5lukrz7zMJjIPb9BDhbpQPOQ4nB4m', //the token is a variable which holds the token
            hash_key: hashkey
        }
        })
        //toast.success(res.data.msg)
    }

    const batchUnstake = async(pid,tokenArray) => {
        let now = Math.floor(Date.now() / 1000)
        const body = {
            address: address,
            poolId: pid,
            tokenId: tokenArray,
            timestamp: now
        }
        const hashkey = enc.Hex.stringify(HmacSHA256(JSON.stringify(body),process.env.REACT_APP_SECRET_KEY))

        const rewards = await axios.post(base_uri + '/BatchUnstake', 
        body, 
        {
        headers: {
            api_key: 'R8NQzvVkmt5g2n5BNr6kkfEhD8Jm6KLzLPf5lukrz7zMJjIPb9BDhbpQPOQ4nB4m', //the token is a variable which holds the token
            hashkey: hashkey
        }
        })
        //toast.success(res.data.msg)
    }

    const addBatchReward = async(pid,tokenid, bonus = 0) => {
        let disableClaim = false;
        if(pid == 0 && disableClaimKong == true){
            disableClaim = true;
        }
        if(pid == 1 && disableClaimBanana == true){
            disableClaim = true;
        }
        if(pid == 2 && disableClaimMutant == true){
            disableClaim = true;
        }
        if(pid == 3 && disableClaimKongGen2 == true){
            disableClaim = true;
        }
        if(disableClaim == false){
            let bonusReward = 0;
            if(pid == 0){
                setDisableClaimKong(true)
                setTimeout(() => {
                    setDisableClaimKong(false)
                }, 6000);
                stakedKongNft.map((staked)=>{
                    if(staked.bonus){
                        let localBonus = 0;
                        localBonus = localBonus + ( (timeNow - staked.claimTime) * staked.bonusRate)
                        localBonus = localBonus + (getKongReward(staked.claimTime,staked.stakedTime) * getTierBonus(staked.stakedTime))
                        localBonus = localBonus + ( localBonus *getTierBonus(staked.stakedTime))
                        bonusReward = bonusReward + localBonus;
                    }
                })
            }else if(pid == 1){
                setDisableClaimBanana(true)
                setTimeout(() => {
                    setDisableClaimBanana(false)
                }, 6000);
            }else if(pid == 2){
                setDisableClaimMutant(true)
                setTimeout(() => {
                    setDisableClaimMutant(false)
                }, 6000);
            }else if(pid == 3){
                setDisableClaimKongGen2(true)
                setTimeout(() => {
                    setDisableClaimKongGen2(false)
                }, 6000);
            }
            setLoading(true)
            const id = toast.loading("Claiming in progress")
            try{
                let now = Math.floor(Date.now() / 1000)
                const body = {
                    address: address,
                    poolId: pid,
                    tokenId: tokenid,
                    bonus: bonusReward,
                    timestamp: now
                }
                const hashkey = enc.Hex.stringify(HmacSHA256(JSON.stringify(body),process.env.REACT_APP_SECRET_KEY))

                const rewards = await axios.post(base_uri + '/BatchClaim', 
                body, 
                {
                headers: {
                    api_key: 'R8NQzvVkmt5g2n5BNr6kkfEhD8Jm6KLzLPf5lukrz7zMJjIPb9BDhbpQPOQ4nB4m', //the token is a variable which holds the token
                    hash_key: hashkey
                }
                })
            toast.dismiss()
            setLoading(false)
            const id = toast.success("Successfully Claimed")
            toast.dismiss()
            

            //await getBalance()
            }catch(err){
                toast.update(id, {render: "Claiming Failed", type: "error", autoClose: 1000});
                setTimeout( ()=> {      
                    toast.dismiss()
                },500)
            }
        }else{
            const id = toast.error("Claiming Cooldown")
            setTimeout( ()=> {      
                toast.dismiss()
            },2000)
        }
    }

    const addReward = async(pid,tokenid) => {
        let disableClaim = false;
        console.log("pid",pid)
        console.log("disabled banan",disableClaimBanana)
        if(pid == 0 && disableClaimKong == true){
            disableClaim = true;
        }
        if(pid == 1 && disableClaimBanana == true){
            disableClaim = true;
        }
        if(pid == 2 && disableClaimMutant == true){
            disableClaim = true;
        }
        if(pid == 3 && disableClaimKongGen2 == true){
            disableClaim = true;
        }

        if(disableClaim == false){
            if(pid == 0){
                setDisableClaimKong(true)
                setTimeout(() => {
                    setDisableClaimKong(false)
                }, 6000);
            }
            if(pid == 1){
                setDisableClaimBanana(true)
                setTimeout(() => {
                    setDisableClaimBanana(false)
                }, 6000);
            }
            if(pid == 2){
                setDisableClaimMutant(true)
                setTimeout(() => {
                    setDisableClaimMutant(false)
                }, 6000);
            }
            if(pid == 3){
                setDisableClaimKongGen2(true)
                setTimeout(() => {
                    setDisableClaimKongGen2(false)
                }, 6000);
            }
            const id = toast.loading("Claiming in progress")
            try{
            let bonusReward = 0;
            let tempStaked;
            if(pid == 0){
                tempStaked = stakedKongNft;
            }else if(pid == 1){
                tempStaked = stakedBananaNft;
            }else if(pid == 3){
                tempStaked = stakedKongGen2Nft;
            }else{
                tempStaked = stakedMutantBananaNft;
            }
            tempStaked.map( (staked)=> {
                if(staked.tokenId == tokenid && staked.bonus){
                    bonusReward = bonusReward + ( (timeNow - staked.claimTime) * staked.bonusRate)
                    let rewardClaimable;
                    if(pid == 0){
                        rewardClaimable = getKongReward(staked.claimTime,staked.stakedTime);
                    }
                    bonusReward = bonusReward + (rewardClaimable * getTierBonus(staked.stakedTime))
                    bonusReward = bonusReward + ( bonusReward *getTierBonus(staked.stakedTime))
                }
            })
            setLoading(true)
            let now = Math.floor(Date.now() / 1000)
            const body = {
                address: address,
                poolId: pid,
                tokenId: tokenid,
                bonus: bonusReward,
                timestamp: now
            }
            const hashkey = enc.Hex.stringify(HmacSHA256(JSON.stringify(body),process.env.REACT_APP_SECRET_KEY))

            const rewards = await axios.post(base_uri + '/claim', 
            body, 
            {
            headers: {
                api_key: 'R8NQzvVkmt5g2n5BNr6kkfEhD8Jm6KLzLPf5lukrz7zMJjIPb9BDhbpQPOQ4nB4m', //the token is a variable which holds the token
                hash_key : hashkey
            }
            })

            toast.update(id, {render: "Claiming Success", type: "Success", autoClose: 1000});
            setTimeout( ()=> {      
                toast.dismiss()
            },500)
            await getBalance()
            if(pid == 0){
                loadStakedKong()
            }
            setLoading(false)
            getBalance()
            }catch(err){
                toast.update(id, {render: "Claiming Failed", type: "error", autoClose: 1000});
                setTimeout( ()=> {      
                    toast.dismiss()
                },500)
            }
        }else{
            const id = toast.error("Claiming Cooldown")
            setTimeout( ()=> {      
                toast.dismiss()
            },2000)
        }
    }

    const BatchUnstakeKong = async() => {
        const signer = web3Provider?.getSigner();
        const contract = new ethers.Contract(stakingContract, stakeAbi, signer);

        try{
            const tx = await contract.batchUnstakeNFT(stakedKongNfts,kongPid)
            let receipt = await toast.promise(
            tx.wait(),
            {
                pending: 'Unstaking in Progress',
                success: 'Unstaking Success',
                error: 'Unstaking Failed'
            })
            await batchUnstake(kongPid,stakedKongNfts)
            await addBatchReward(kongPid,stakedKongNfts,0)
            loadKongNft()
            loadStakedKong()
            toast.success("unstake Success")
        }catch(err){
            console.log(err)
            toast.success("unstake Failed")
        }
    }

    const getLockTimeLeft = (lockTime) => {
        let now = Math.floor(Date.now() / 1000);
        let dayLeft = lockTime - timeNow;
        dayLeft = dayLeft/86400;
        return dayLeft;
    }

    const getStakedTier = (stakedTime) => {
        let now = Math.floor(Date.now() / 1000);
        let dayAfter = timeNow - stakedTime;
        dayAfter = dayAfter/86400;
        let tier = 1;
        if(dayAfter<= array_tier[0]){
            tier = 1;
        }else if(dayAfter> array_tier[0] && dayAfter <= array_tier[1] + array_tier[0]){
            tier = 2;
            dayAfter = dayAfter - array_tier[0];
        }else if(dayAfter > array_tier[1] + array_tier[0] && dayAfter <= array_tier[2] + array_tier[1] + array_tier[0]){
            tier = 3
            dayAfter = dayAfter - (array_tier[0] +array_tier[1])
        }else if(dayAfter > array_tier[2] + array_tier[1] + array_tier[0]  && dayAfter <= array_tier[3] + array_tier[2] + array_tier[1] + array_tier[0]){
            tier = 4
            dayAfter = dayAfter - (array_tier[0] +array_tier[1] + array_tier[2])
        }else if(dayAfter > array_tier[3] + array_tier[2] + array_tier[1] + array_tier[0] && dayAfter <= array_tier[4] + array_tier[3] + array_tier[2] + array_tier[1] + array_tier[0]){
            tier = 5
            dayAfter = dayAfter - (array_tier[0] +array_tier[1] + array_tier[2]+ array_tier[3])
        }
        dayAfter = array_tier[tier-1] - dayAfter;
        return dayAfter.toFixed();
    }

    const getProgressTier = (stakedTime) => {
        let dayAfter = timeNow - stakedTime;
        dayAfter = dayAfter/86400;
        dayAfter = dayAfter
        let tier = 1;
        
        if(dayAfter<= array_tier[0]){
            tier = 1;
        }else if(dayAfter> array_tier[0] && dayAfter <= array_tier[1] + array_tier[0]){
            tier = 2;
            dayAfter = dayAfter - array_tier[0];
        }else if(dayAfter > array_tier[1] + array_tier[0] && dayAfter <= array_tier[2] + array_tier[1] + array_tier[0]){
            tier = 3
            dayAfter = dayAfter - (array_tier[0] +array_tier[1])
        }else if(dayAfter > array_tier[2] + array_tier[1] + array_tier[0]  && dayAfter <= array_tier[3] + array_tier[2] + array_tier[1] + array_tier[0]){
            tier = 4
            dayAfter = dayAfter - (array_tier[0] +array_tier[1] + array_tier[2])
        }else if(dayAfter > array_tier[3] + array_tier[2] + array_tier[1] + array_tier[0] && dayAfter <= array_tier[4] + array_tier[3] + array_tier[2] + array_tier[1] + array_tier[0]){
            tier = 5
            dayAfter = dayAfter - (array_tier[0] +array_tier[1] + array_tier[2]+ array_tier[3])
        }
        dayAfter = array_tier[tier-1] - dayAfter; // 7 - 7 = 0
        dayAfter = dayAfter - array_tier[tier-1]; // 
        dayAfter = Math.abs(dayAfter)
        dayAfter = (dayAfter/array_tier[tier-1]) * 100; // 0/14
        return Math.floor(dayAfter);
    }

    const getStakedTiers = (stakedTime) => {
        let dayAfter = timeNow - stakedTime;
        dayAfter = dayAfter/86400;
        let tier = 1;
        if(dayAfter<= array_tier[0]){
            tier = 1;
        }else if(dayAfter> array_tier[0] && dayAfter <= array_tier[1] + array_tier[0]){
            tier = 2;
        }else if(dayAfter > array_tier[1] + array_tier[0] && dayAfter <= array_tier[2] + array_tier[1] + array_tier[0]){
            tier = 3
        }else if(dayAfter > array_tier[2] + array_tier[1] + array_tier[0]  && dayAfter <= array_tier[3] + array_tier[2] + array_tier[1] + array_tier[0]){
            tier = 4
        }else if(dayAfter > array_tier[3] + array_tier[2] + array_tier[1] + array_tier[0] && dayAfter <= array_tier[4] + array_tier[3] + array_tier[2] + array_tier[1] + array_tier[0]){
            tier = 5
        }
        return tier;
    }

    const getStakedTierBonus = (stakedTime) => {
        let dayAfter = timeNow - stakedTime;
        dayAfter = dayAfter/86400;
        let tier = 1;
        if(dayAfter<= array_tier[0]){
            tier = 1;
        }else if(dayAfter> array_tier[0] && dayAfter <= array_tier[1] + array_tier[0]){
            tier = 2;
        }else if(dayAfter > array_tier[1] + array_tier[0] && dayAfter <= array_tier[2] + array_tier[1] + array_tier[0]){
            tier = 3
        }else if(dayAfter > array_tier[2] + array_tier[1] + array_tier[0]  && dayAfter <= array_tier[3] + array_tier[2] + array_tier[1] + array_tier[0]){
            tier = 4
        }else if(dayAfter > array_tier[3] + array_tier[2] + array_tier[1] + array_tier[0] && dayAfter <= array_tier[4] + array_tier[3] + array_tier[2] + array_tier[1] + array_tier[0]){
            tier = 5
        }
        return array_tier_bonus[tier-1];
    }

    const getTierBonus = (stakedTime) => {
        let dayAfter = timeNow - stakedTime;
        dayAfter = dayAfter/86400;
        let tier = 0;
        if(dayAfter<= array_tier[0]){
            tier = 1;
        }else if(dayAfter> array_tier[0] && dayAfter <= array_tier[1] + array_tier[0]){
            tier = 2;
        }else if(dayAfter > array_tier[1] + array_tier[0] && dayAfter <= array_tier[2] + array_tier[1] + array_tier[0]){
            tier = 3
        }else if(dayAfter > array_tier[2] + array_tier[1] + array_tier[0]  && dayAfter <= array_tier[3] + array_tier[2] + array_tier[1] + array_tier[0]){
            tier = 4
        }else if(dayAfter > array_tier[3] + array_tier[2] + array_tier[1] + array_tier[0] && dayAfter <= array_tier[4] + array_tier[3] + array_tier[2] + array_tier[1] + array_tier[0]){
            tier = 5
        }
        if(tier == 0){
            return 0
        }

        console.log("tier bonus", tier_bonus_number[tier-1])
        
        return tier_bonus_number[tier-1];
    }


    const getKongReward = (claimTime,stakedTime) => {
        let secLeft = timeNow - claimTime;
        let rewards = secLeft * (kongRewardRate)
        rewards = rewards + (rewards * getTierBonus(stakedTime))
        return rewards.toFixed(3);
    }


    const getBonusReward = (claimTime, rate) => {
        if(rate != undefined && rate != null){
            let secLeft = timeNow - claimTime;
            let rewards = secLeft * (rate)
            return rewards.toFixed(3);
        }
        return 0
    }



    //banana part 


    return (
        <>

            <Preloader handleLoad={handleLoad} />
            <Header siteTitle={`Title`} balances={balances}/>
            <RingSpinnerOverlay loading={loading} overlayColor="rgba(255,255,255,0.2)">

            </RingSpinnerOverlay>

            <div className='staking'>
                <div className="estimated-earning">
                    <p style={{marginTop:"auto",marginBottom:"auto",marginLeft:"5px"}}>
                        Total Estimated Earnings per Day : 
                    </p>
                    <div style={{display:"flex"}}>
                        <img src="https://assets.supremekong.com/images/banana-token.png" style={{width:"30px",height:"30px"}}></img>
                        <p style={{marginTop:"auto",marginBottom:"auto",marginLeft:"5px"}}>
                            
                        {totalAcrueReward.toLocaleString()}
                        </p>
                    </div>
                </div>
                <Accordion allowZeroExpanded allowMultipleExpanded={true}>
                { kongNft.length > 0 &&
                <div className='staking-kong-wallet'>
                    <AccordionItem color='#F2B90C' >
                    <AccordionItemHeading color='#F2B90C'>
                        <AccordionItemButton color='#F2B90C' className="staking-kong-title">
                        <p>Supreme Kong [{kongNft.length}]</p>
                        <AccordionItemState>
                            {({ expanded }) => (expanded ? 
                                <div style={{fontSize:'24px', marginLeft:'5px', marginBottom:'12px'}}>-</div> 
                                : 
                                <div style={{fontSize:'24px', marginLeft:'5px', marginBottom:'12px'}}>+</div>
                            )}
                        </AccordionItemState>
                        <div className="staking-kong-stakingbutton">
                        
                        {
                            (approvedKong && kongNft.length > 0) &&
                            <button className="DiscordButton" style={{paddingLeft:"50px",paddingRight:"50px"}} onClick={batchStakeKong}>
                                Stake All
                            </button>
                        }
                        {
                            !approvedKong &&
                            <button className="DiscordButton" style={{paddingLeft:"50px",paddingRight:"50px"}} onClick={kongApproveAll}>
                                VALIDATE All
                            </button>
                        }
                        </div>
                    </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                    <div className="staking-kong-content">
                        

                        {kongNft.map((object, i) => 
                            <div className="card-kong-nft" key={i}>
                                <img src={"https://raffle.supremekong.com/images/"+object+".png"}></img>
                                <h4>Supreme Kong #{object}</h4>
                                
                                {
                                    approvedKong &&    
                                    <button className="DiscordButton" style={{paddingLeft:"100px",paddingRight:"100px",margin:"auto",marginTop:"10px"}} onClick={() => stakeKong(object)}>
                                        Stake
                                    </button>
                                }
                            </div>
                        )}

                    </div>
                    </AccordionItemPanel>
                    </AccordionItem>
                </div>
                }

            

            { stakedKongNft.length > 0 &&
                <div className='staking-kong-wallet'>
                    <AccordionItem color='#F2B90C'>
                    <AccordionItemHeading color='#F2B90C'>
                    <AccordionItemButton color='#F2B90C' className="staking-kong-title">
                        <p>Staked Supreme Kong [{stakedKongNft.length}]</p>
                        <AccordionItemState>
                            {({ expanded }) => (expanded ? 
                                <div style={{fontSize:'24px', marginLeft:'5px', marginBottom:'12px'}}>-</div> 
                                : 
                                <div style={{fontSize:'24px', marginLeft:'5px', marginBottom:'12px'}}>+</div>
                            )}
                        </AccordionItemState>
                        <div className="staking-kong-stakingbutton">
                        { stakedKongNft.length > 0 && <div className="stake-section-action">
                            <button onClick={BatchUnstakeKong} className="SectionButton" >
                                Unstake All
                            </button>
                            { disableClaimKong == false &&
                                <button onClick={() => addBatchReward(kongPid, stakedKongNfts, 0)} className="SectionButton" >
                                    Claim All
                                </button>
                            }

                            { disableClaimKong &&
                                <button onClick={() => addBatchReward(kongPid, stakedKongNfts, 0)} className="SectionButtonDisabled" disabled >
                                    Claim All
                                </button>
                            }
                        </div>
                        }
                        </div>
                    </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                    <div className="staking-kong-content">
                        

                        {stakedKongNft.map((object, i) => 
                            <div className="card-staked-nft" key={i}>
                                <img src={"https://raffle.supremekong.com/images/"+object.tokenId+".png"}></img>
                                <h4 style={{display:"flex"}}>Supreme Kong #{object.tokenId} 
                                
                                </h4>
                                <div className="card-kong-rewards">
                                    <h5>Tier {getStakedTiers(object.stakedTime)}  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  +{getStakedTierBonus(object.stakedTime)} Bonus</h5>
                                    <h5 className="right">{getStakedTier(object.stakedTime)} days to next Tier</h5>
                                </div>
                                <ProgressBar bgcolor={"#F2B90C"} completed={getProgressTier(object.stakedTime)} />
                                { object.bonus && 
                                    <p style={{textAlign:"center",fontSize:"12px",marginTop:"5px"}}>
                                       BANANA PAIR BONUS : {getBonusReward(object.claimTime,object.bonusRate)}
                                    </p>
                                }
                                <p style={{textAlign:"center",fontSize:"12px",marginTop:"5px"}}>
                                    BASE EARNINGS : {getKongReward(object.claimTime,object.stakedTime)}
                                </p>
                                <div className='priceTxt' style={{textAlign:"center",fontSize:"12px",marginLeft:"auto",marginRight:"auto",width:"fit-content"}}>
                                    <img src="https://assets.supremekong.com/images/banana-token.png" style={{width:"30px",height:"30px"}}></img>
                                <p style={{textAlign:"center",fontSize:"12px",marginTop:"5px"}}>
                                    { (parseFloat(getKongReward(object.claimTime,object.stakedTime)) + parseFloat(getBonusReward(object.claimTime,object.bonusRate))).toFixed(3)} Total Claimable 
                                </p>
                                </div>
                                {/* <p style={{textDecoration:"underline",textAlign:"center",marginTop:"15px"}} onClick={() => unstakeKong(object.tokenId)}>
                                    Unstake
                                </p> */}
                                { getLockTimeLeft(object.lockTime) > 0 &&
                                    <button className="UnstakeButtonDisabled" style={{paddingLeft:"90px",paddingRight:"90px",margin:"auto",marginTop:"10px"}} onClick={() => unstakeKong(object.tokenId)} disabled>
                                        Unstake
                                    </button>
                                }
                                { getLockTimeLeft(object.lockTime) < 0 &&
                                   <button className="UnstakeButton" style={{paddingLeft:"90px",paddingRight:"90px",margin:"auto",marginTop:"10px"}} onClick={() => unstakeKong(object.tokenId)}>
                                        Unstake
                                    </button>
                                }
                                { disableClaimKong == false &&
                                    <button className="DiscordButton" style={{paddingLeft:"100px",paddingRight:"100px",margin:"auto",marginTop:"10px"}} onClick={() => addReward(kongPid,object.tokenId)}>
                                        Claim
                                    </button>
                                }
                                { disableClaimKong &&
                                    <button className="UnstakeButtonDisabled" style={{paddingLeft:"100px",paddingRight:"100px",margin:"auto",marginTop:"10px"}} >
                                        Claim
                                    </button>
                                }
                            </div>
                        )}

                    </div>
                    </AccordionItemPanel>
                    </AccordionItem>
                </div>
            }
            </Accordion>
            </div>
        </>
    );
}

export default Staking;
