import React, { useEffect, useState } from "react";
import useScrollCue from "../../hooks/useScrollCue";
//import handleJquery from "./helpers/handleJquery";
import NavMenu from "../../components/NavMenu";
import HeroCard from "../../components/jnglpage/HeroCard";
import SubHeroCard from "../../components/jnglpage/SubHeroCard";
import BuyJnglCard from "../../components/jnglpage/BuyJnglCard";
import JnglMembershipCard from "../../components/jnglpage/JnglMembershipCard";
import JnglRoadmapCard from "../../components/jnglpage/JnglRoadmapCard";
import JunglenomicsCard from "../../components/jnglpage/JunglenomicsCard";
import JnglFooter from "../../components/jnglpage/JnglFooter";
import axios from "axios"

const HomePage = () => {
  const [marketData, setMarketData] = useState({
    price: null,
    fully_diluted_market_cap: null,
    market_cap: null,
    volume_24h: null,
    percent_change_24h: null,
  });

  useEffect(() => {
    fetchMarketData();
  }, []);

  const fetchMarketData = async () => {
    try {
      const response = await axios.get("https://api.supremekong.com/getJnglMC");
      setMarketData(response.data);
    } catch (error) {
      console.error("Error fetching market data:", error);
    }
  };
  useScrollCue();

  return (
    <>
      <NavMenu />
      <HeroCard />
      <SubHeroCard />
      <BuyJnglCard />
      <JnglMembershipCard />
      <JnglRoadmapCard />
      <JunglenomicsCard />
      <JnglFooter />
    </>
  );
};

export default HomePage;
