import * as React from 'react';
import Logo from "../../assets/icons/Logo-new.png";
import DiscordButton from '../lib/DiscordButton/DiscordButton';
import TwitterIcon from '../../assets/icons/twitter.svg';
import InstagramIcon from '../../assets/icons/instagram.svg';
import navigateTo from '../../utils/navigateTo';
import { motion } from 'framer-motion';

import closeIcon from '../../assets/icons/close.svg';
import burgerIcon from '../../assets/icons/burger.svg';
import discordIcon from '../../assets/icons/discord.svg';
import './Header.scss';

const menuBtn = {
  open: { rotate: 0 },
  closed: { rotate: 180 },
};

const nav = {
  open: { opacity: 1, x: 0 },
  closed: { opacity: 0, x: '130%' },
};
const Header = ({ siteTitle }) => {
  const [open, setOpen] = React.useState(false);
  function onNavigate(e) {
    navigateTo(e);
  }

  function handleOpen() {
    setOpen(!open);
  }
  return (
    <header className='Header'>
      <div className='Header__content'>
        <a className='Header__logo'  href='/'>
          <img src={Logo} alt='' />
        </a>
        <ul className='Header__list'>
          <li>
            <a href='/staking'>
              Stake
            </a>
          </li>
          <li>
            <a href='/marketplace'>
              Marketplace
            </a>
          </li>
          <li>
            <a href='/faq'>
              FAQ
            </a>
          </li>
        </ul>
        <ul className='Header__social'>
          <a
            className='Header__social__anchor'
            href='https://twitter.com/Supremekongnft'
            target='_blank'
            rel='noreferrer'
          >
            <img src={TwitterIcon} alt='' />
          </a>
          <a
            className='Header__social__anchor'
            href='https://discord.com/invite/supremekong'
            target='_blank'
            rel='noreferrer'
          >
            <img src={discordIcon} alt='' />
          </a>
          {/* <DiscordButton href='https://discord.com/invite/supremekong' text='JOIN OUR DISCORD' blank /> */}
        </ul>
        <motion.button
          animate={open ? 'open' : 'closed'}
          initial={'closed'}
          variants={menuBtn}
          onClick={handleOpen}
          className='Header__menu__btn'
        >
          <img src={open ? closeIcon : burgerIcon} alt='' />
        </motion.button>
      </div>
      <motion.nav
        animate={open ? 'open' : 'closed'}
        initial={'closed'}
        variants={nav}
        onClick={handleOpen}
        className='Header__menu__btn'
        className='Header__motion__nav'
      >
        <ul className='Header__motion__nav-anchors'>
          <li>
            <a href='/staking'>
              Stake
            </a>
          </li>
          <li>
            <a href='/marketplace'>
              Marketplace
            </a>
          </li>
          <li>
            <a href='/transaction'>
              History
            </a>
          </li>
          <li>
            <a href='/stats'>
              Stats
            </a>
          </li>
          <li>
            <a href='/faq'>
              FAQ
            </a>
          </li>
        </ul>
        <ul className='Header__social'>
          <a
            className='Header__social__anchor'
            href='https://twitter.com/Supremekongnft'
            target='_blank'
            rel='noreferrer'
          >
            <img src={TwitterIcon} alt='' />
          </a>
          <a
            className='Header__social__anchor'
            href='https://discord.com/invite/supremekong'
            target='_blank'
            rel='noreferrer'
          >
            <img src={discordIcon} alt='' />
          </a>
          
        </ul>
      </motion.nav>
    </header>
  );
};

export default Header;
