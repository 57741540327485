import { motion } from 'framer-motion';
import React from 'react';
import { paragraphAnimation } from '../../utils/config';
import AnimateTextInView from '../lib/SectionTitle/AnimateTextInView';
import Tokenomic from '../../assets/images/htb.png'
import './About.scss';
import AboutSlider from './AboutSlider';
import uniImg from '../../assets/images/uni-logo.png'
import swapImg from '../../assets/images/swap-logo.png'
import ethImg from '../../assets/images/eth-logo.png'
import walletImg from '../../assets/images/wallet-logo.png'

function About() {
  return (
    <div id='about' className='About-jngl'>
        {/* <motion.h1 {...paragraphAnimation} className='About__title Title'>
          UNQUESTIONED DOMINANCE FINALLY UNLEASHED
        </motion.h1> */}
        <div style={{flexDirection:'column'}} className='flex fwrap fjc '>
          <div class="junglenomic-title" style={{backgroundColor:'rgb(68, 255, 80)', color:'black'}}>
            <a href="https://app.uniswap.org/swap?chain=mainnet&amp;inputCurrency=eth&amp;outputCurrency=0x4C45bbEc2fF7810ef4a77ad7BD4757C446Fe4155">Buy $JNGL on Uniswap</a>
          </div>
        </div>
      {/* <AboutSlider /> */}
    </div>
  );
}

export default About;
