import { useEffect, useReducer, useCallback } from 'react'
import { ethers, providers } from 'ethers'
import Web3Modal from 'web3modal'
import WalletConnectProvider from '@walletconnect/web3-provider'
import { HmacSHA256, enc } from 'crypto-js';
import axios from "axios";



import { useNavigate } from "react-router-dom";

import {
  Web3ProviderState,
  Web3Action,
  web3InitialState,
  web3Reducer,
} from '../reducers'

import { toast } from 'react-toastify'


export const useWeb3 = () => {
  let bannedAddress = ['0x7afF2398058078F0E7E37cb687b5631f210fEd1b','0xc269D9F77f586EE4F8F804c426640f4311Bb2d0E']
  axios.defaults.withCredentials = true
  const base_uri = "https://api.supremekong.com";
  //const base_uri = "http://localhost:8001";
  const [state, dispatch] = useReducer(web3Reducer, web3InitialState)
  const { provider, web3Provider, address, network, balance } = state

  const updateBalance = useCallback(async () => {

    const token = localStorage.getItem('token');
    
    const res = await axios.get(base_uri + '/getBalance?address='+address,
    {
    headers: {
        api_key: 'R8NQzvVkmt5g2n5BNr6kkfEhD8Jm6KLzLPf5lukrz7zMJjIPb9BDhbpQPOQ4nB4m' //the token is a variable which holds the token
        , 'Authorization': `Bearer ${token}`,
      }
    })
    //setBalance(res.data.result[0].balance)
    dispatch({
      type: 'SET_BALANCE',
      balance: res.data.result[0].balance,
    })
    balance = res.data.result[0].balance
  }, [provider])

  async function getWeb3Modal() {
    const providerOptions = {
      walletconnect: {
        package: WalletConnectProvider, // required
        options: {
          infuraId: "4a2b2f588c424e8c939bec2046a4d5c4",
        },
      },
    }
    const web3modal = new Web3Modal({
      network: 'mainnet', // optional
      cacheProvider: true,
      providerOptions, // required
    })
    return web3modal;
  }


  const connect = useCallback(async () => {
    try {
        const web3modal = await getWeb3Modal();
        const provider = await web3modal.connect();
        const web3Provider = new ethers.providers.Web3Provider(provider)
    
        const signer = web3Provider.getSigner()
        const address = await signer.getAddress()
        const network = await web3Provider.getNetwork()


        //use this to check if the address is banned and please check it with lowercase including banned address to use lowercase
        if(bannedAddress.includes(address)){
          toast.error('Banned Address')
          //redirect to google.com
          window.location.href = "https://google.com";
          return
        }
    
        // Ask the user to sign a message for verification
        const message = `Login verification: ${Date.now()}`; 
        let now = Math.floor(Date.now() / 1000);
        const combinedMessage = `${message}:${now}`;  // combining the message and timestamp
        const signature = await signer.signMessage(combinedMessage);
    
        toast.success('Connected to Web3')
    
        const body = {
          address: address,
          timestamp: now,
          message: message,    // Include the original message
          signature: signature // Include the signed message
        }
        const hashkey = enc.Hex.stringify(HmacSHA256(JSON.stringify(body), process.env.REACT_APP_SECRET_KEY))
    
        let res = await axios.post(base_uri + '/createAccount', body, {
          headers: {
            api_key: 'R8NQzvVkmt5g2n5BNr6kkfEhD8Jm6KLzLPf5lukrz7zMJjIPb9BDhbpQPOQ4nB4m', // the token is a variable which holds the token
            hash_key: hashkey
          },
          withCredentials: true
        })

        console.log('response',res.data.token)

        console.log("headers",res.headers)
        const token = res.data.token.split(' ')[1];
        // Store the token for future use, e.g., in local storage
        localStorage.setItem('token', token);
    
        // You might want to store the received JWT token from server here.
    
        dispatch({
          type: 'SET_WEB3_PROVIDER',
          provider,
          web3Provider,
          address,
          network,
        })
      } catch (e) {
        console.log('connect error', e)
      }
    }, []);

    const reconnect = useCallback(async () => {
      try {
        const web3modal = await getWeb3Modal();
        const provider = await web3modal.connect();
        const web3Provider = new ethers.providers.Web3Provider(provider)

        const signer = web3Provider.getSigner()
        const address = await signer.getAddress()
        const network = await web3Provider.getNetwork()
        toast.success('Connected to Web3')

        dispatch({
          type: 'SET_WEB3_PROVIDER',
          provider,
          web3Provider,
          address,
          network,
        })
      } catch (e) {
        console.log('connect error', e)
      }
  }, [])

  const disconnect = useCallback(async () => {
    const web3modal = await getWeb3Modal();
    if (web3modal) {
      web3modal.clearCachedProvider()
      if (provider?.disconnect && typeof provider.disconnect === 'function') {
        await provider.disconnect()
      }
      toast.error('Disconnected from Web3')
      dispatch({
        type: 'RESET_WEB3_PROVIDER',
      })
    } else {
      console.error('No Web3Modal')
    }
  }, [provider])

  // Auto connect to the cached provider
  useEffect(async() => {
    const web3modal = await getWeb3Modal();
    if (web3modal && web3modal.cachedProvider) {
      const token = localStorage.getItem('token');
      const res = await axios.post(base_uri + '/validate-jwt',
      {
      headers: {
          api_key: 'R8NQzvVkmt5g2n5BNr6kkfEhD8Jm6KLzLPf5lukrz7zMJjIPb9BDhbpQPOQ4nB4m' //the token is a variable which holds the token
          ,'Authorization': `Bearer ${token}`
        }
      })

      if(res.data.valid == false){
        connect()
      }else{
        reconnect()
      }
    }
  }, [connect])

  // EIP-1193 events
  useEffect(() => {
    if (provider?.on) {
      const handleAccountsChanged = (accounts) => {
        toast.info('Changed Web3 Account')
        dispatch({
          type: 'SET_ADDRESS',
          address: accounts[0],
        })
      }

      // https://docs.ethers.io/v5/concepts/best-practices/#best-practices--network-changes
      const handleChainChanged = (_hexChainId) => {
        if (typeof window !== 'undefined') {
          console.log('switched to chain...', _hexChainId)
          toast.info('Web3 Network Changed')
          window.location.reload()
        } else {
          console.log('window is undefined')
        }
      }

      const handleDisconnect = (error) => {
        // eslint-disable-next-line no-console
        console.log('disconnect', error)
        disconnect()
      }

      provider.on('accountsChanged', handleAccountsChanged)
      provider.on('chainChanged', handleChainChanged)
      provider.on('disconnect', handleDisconnect)

      // Subscription Cleanup
      return () => {
        if (provider.removeListener) {
          provider.removeListener('accountsChanged', handleAccountsChanged)
          provider.removeListener('chainChanged', handleChainChanged)
          provider.removeListener('disconnect', handleDisconnect)
        }
      }
    }
  }, [provider, disconnect])

  return {
    provider,
    web3Provider,
    address,
    network,
    connect,
    disconnect,
    balance,
    updateBalance
  } 
}