import { motion } from 'framer-motion';
import React , {useEffect} from 'react';
import { paragraphAnimation } from '../../utils/config';
import { ethers } from 'ethers'
import Web3Modal from 'web3modal'
import token from '../../assets/images/jngl-token.png'

import Header from '../../components/HeaderJngl/Header';

import './Vesting.scss';
import { toast } from 'react-toastify';

import nftAbi from '../../helper/stakeAbi.json';
import kongRedeemAbi from '../../helper/kongRedeem.json'
import WalletConnectProvider from '@walletconnect/web3-provider'
import { useWeb3Context } from '../../context'
import axios from 'axios';

let web3Modal;

const providerOptions = {
  walletconnect: {
    package: WalletConnectProvider, // required
  },
}

if (typeof window !== 'undefined') {
  web3Modal = new Web3Modal({
    network: 'mainnet', // optional
    cacheProvider: true,
    providerOptions, // required
  })

  console.log(web3Modal)
}


export default function Mint() {
  axios.defaults.withCredentials = true;
  const { web3Provider, connect, disconnect, address } = useWeb3Context()
  //const address = "0x047862412AF18dA4c549549630887dbA1AF6C0F2"
  const [mintVisible, setMintVisible] = React.useState(true);

  // const [countDown, setCountDown] = React.useState(
  //   countDownDate - new Date().getTime()
  // ); 

  const [eligibleAmount,setEligibleAmount] = React.useState(0)


  const [unreleasedAmount,setUnreleasedAmount] = React.useState(0)

  const [releasedAmount,setReleasedAmount] = React.useState(0)

  const [totalAmount,setTotalAmount] = React.useState(0)

  const [kongOwned,setKongOwned] = React.useState(0)

  const redeemContractAddress = "0x14D40100EF7e1Fa7f9B9aacb3fC5571452dc0712"

  const kongContractAddress = "0x19761CB1f7b4A489634ea9c9360587F6f8D87A32"

  const stakignContractAddress = "0x6D2ca1920eCbAf404C6150D44Ac568A7046986A7"

  useEffect(async() => {
    //Runs only on the first render
    if(web3Provider){
      await loadAmount();
    }else{
    }
  }, [web3Provider]);


  const handleClaimTokens = async () => {
    try{
      if (!web3Provider) {
        toast.error('Please connect to a wallet first')
        return;
      }
      const signer = web3Provider.getSigner();
      const kongRedeem = new ethers.Contract(redeemContractAddress, kongRedeemAbi, signer);
    
      // Get the required ETH amount
    
      // Initiate claim tokens transaction
      const claimTx = await kongRedeem.claimRewards(0);
      await claimTx.wait();
    
      // You can update balance or perform other operations after successful claim
      // ... 
    }catch(err){
      toast.error('Claiming FAILED')
    }
  };

  const loadAmount = async () => {
    try {
      const signer = web3Provider.getSigner();
      const nftcontract = new ethers.Contract(stakignContractAddress, nftAbi, signer);
      const kongRedeem = new ethers.Contract(redeemContractAddress, kongRedeemAbi, signer);

      const stakedKong = await nftcontract.stakedNfts(address,0);

      const amount = stakedKong.length;

      const eligible = await kongRedeem.simulateYield(0,address);  

      const yieldInEth = ethers.utils.formatEther(eligible)

      const roundedBalanceInEther = Math.abs(yieldInEth);
      const formattedBalance = roundedBalanceInEther.toLocaleString(undefined, {minimumFractionDigits: 3, maximumFractionDigits:3});

      setTotalAmount(formattedBalance)

      setKongOwned(amount.toString())

      
    } catch (error) {
    }
}

  return (
    <>
    <Header siteTitle={`Title`}/>
    <motion.section 
    {...paragraphAnimation}
    id='Vesting' className='Vesting'>
      <div className='Vesting__content'>
        <div className='flex-vesting-container'>
          <div className='flex-item-left' style={{color:'white'}}>

            <div className='flex-item-left-title'>
              <div className='claim-text-container'>
                    CLAIM
              </div>

              <div className="alpha-text-container">
                    $JNGL
              </div>
            </div>

            <div className='flex-item-right-title'>
              <div className='claim-text-container'>
                    FROM OWNING SK1
              </div>
            </div>

          </div>

          <div className='flex-vesting-left' style={{color:'white'}}>
          

            <div className='wolfvesting-text-container'>
                  Total SUPREME KONG NFT
            </div>

            <div className="address-text-container" style={{width:'100%'}}>
                  {kongOwned}
            </div>

            <div className='wolfvesting-text-container'>
                  Claimable $JNGL
            </div>

            <div className="address-text-container" style={{width:'100%'}}>
                  {totalAmount}
            </div>

            <button className='vesting-button-claim' onClick={handleClaimTokens}>
              Claim
            </button>


          </div>


        </div>
      </div>
    </motion.section>
    </>
  );
}
