import React from 'react';

import DiscordIcon from '../../../assets/icons/discord.svg';
import './DiscordButton.scss';
import { useWeb3Context } from '../../../context'
import axios from "axios";

function ConnectWalletButton(props) {
  axios.defaults.withCredentials = true;
  const base_uri = "https://api.supremekong.com";

  const { web3Provider, connect, disconnect, address } = useWeb3Context()

  const [balance,setBalance] = React.useState(0)

  const getBalance = async() => {
    const res = await axios.get(base_uri + '/getBalance?address='+address,
    {
    headers: {
        api_key: 'R8NQzvVkmt5g2n5BNr6kkfEhD8Jm6KLzLPf5lukrz7zMJjIPb9BDhbpQPOQ4nB4m' //the token is a variable which holds the token
    }
    })
    setBalance(res.data.result[0].balance)
  }
  React.useEffect(() => {
    if(props){
      setBalance(props.balances.balances)
    }
  }, [props]);

  React.useEffect(() => {
    if(web3Provider){
    }
  }, [web3Provider]);


  return (
    // <button className='DiscordButton'>
    //     {text}
    // </button>

    web3Provider ? (
      // <button className='DiscordButton' onClick={disconnect}>
      //    Disconnect
      // </button>
      <div style={{"fontSize":"12px","marginTop":"18px",display:"flex",marginLeft:"0px"}}>
    
      <div style={{display:"flex",marginLeft:"10px"}}>
      <img src="https://assets.supremekong.com/images/banana-token.png" style={{width:"30px",height:"30px"}}></img>
        <p style={{marginTop:"auto",marginBottom:"auto",marginLeft:"5px"}}>
          {balance.toLocaleString()}
        </p>
      </div>
      </div>
    ) : (
      <></>
    )
  );
}
export default ConnectWalletButton;
