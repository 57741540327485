import * as React from "react";
import DiscordButton from '../../components/lib/DiscordButton/DiscordButton';
import Preloader from '../../components/Preloader/Preloader';
import Header from '../../components/HeaderWallet/HeaderWallet';
import { useWeb3Context } from '../../context';
import TransactionTable from './TransactionTable';  // Update this with the actual path to the TransactionTable component
//... other imports
import { useNavigate } from "react-router-dom";
import axios from "axios";
import './Marketplace.scss';

function Transaction() {
    axios.defaults.withCredentials = true;
    let navigate = useNavigate();
    const base_uri = "https://api.supremekong.com";
    //const base_uri = "http://localhost:8001";
    const { web3Provider, connect, disconnect, address } = useWeb3Context();
    const [loaded, setLoaded] = React.useState(false);
    const [balance, setBalance] = React.useState(0);

    const handleLoad = React.useMemo(() => {
        setLoaded(true)
    }, []);

    React.useEffect(async() => {
        //Runs only on the first render
        if(web3Provider){
            getBalance()
        }
    }, [web3Provider])

    const getBalance = async() => {
        const res = await axios.get(base_uri + '/getBalance?address='+address,
        {
        headers: {
            api_key: 'R8NQzvVkmt5g2n5BNr6kkfEhD8Jm6KLzLPf5lukrz7zMJjIPb9BDhbpQPOQ4nB4m' //the token is a variable which holds the token
        }
        })
        setBalance(res.data.result[0].balance)
    }

    // ... other functions

    return (
        <>
            <Preloader handleLoad={handleLoad} />
            <Header siteTitle={`Title`} balances={balance}/>
            <div className='transaction-container'>
                <div className="transaction-title">
                    Transaction History
                </div>
                {address && <TransactionTable address={address} />}
            </div>
        </>
    );
}

export default Transaction;