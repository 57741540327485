import * as React from "react"

import './Inventory.scss';
import DiscordButton from '../../components/lib/DiscordButton/DiscordButton';
import Preloader from '../../components/Preloader/Preloader';
import Header from '../../components/HeaderWallet/HeaderWallet';
import { useWeb3Context } from '../../context'
import { useNavigate, useParams } from "react-router-dom";
import { ethers } from 'ethers'
import { toast } from 'react-toastify';
import axios from "axios";
import { RingSpinnerOverlay } from 'react-spinner-overlay'
import mutantbanana from '../../assets/images/mutantbanana.png'
import supremebanana from '../../assets/images/supreme-bananas.jpeg'
import arrow from '../../assets/images/arrow.png'
import ProgressBar from "../../components/Progressbar/Progressbars";
import { fail } from "assert";
import { Metaplex } from "@metaplex-foundation/js";
import { Connection, clusterApiUrl, PublicKey } from "@solana/web3.js";
import { HmacSHA256, enc } from 'crypto-js';
const connection = new Connection("https://api.metaplex.solana.com/");
const metaplex = new Metaplex(connection);

const mutantbananaAbi = require('../../helper/mutantBanana.json')
const stakingAbi = require('../../helper/stakeAbi.json')
const redeemAbi = require('../../helper/redeemAbi.json')
const whitelist = require('../../helper/allow.json')

let interval;

function Inventory() {
    axios.defaults.withCredentials = true
    let navigate = useNavigate()

    let { id } = useParams();

    //const base_uri = "http://localhost:8001";
    const base_uri = "https://api.supremekong.com";


    const bananaContract = process.env.REACT_APP_BANANA_CONTRACT;
    const mutantBananaContract = process.env.REACT_APP_MUTANT_BANANA_CONTRACT;
    const stakingContract = process.env.REACT_APP_STAKING_CONTRACT;

    const redeemContract = process.env.REACT_APP_REDEEM_CONTRACT;

    const redeemSolana = process.env.REACT_APP_SOLANA_CONTRACT;

    const burnCost = 5;


    const { web3Provider, connect, disconnect, address } = useWeb3Context()

    const [search, setSearch] = React.useState("")

    const [redeem, setRedeem] = React.useState(false)

    const [loaded, setLoaded] = React.useState(false)

    const [owner, setOwner] = React.useState(false)

    const [potion, setPotion] = React.useState(0)

    const [load, setLoad] = React.useState(false)

    const [transferFunction, setTransferFunction] = React.useState(null);

    const [ticker, setTicker] = React.useState("")

    const [tokenId, setTokenId] = React.useState(-1)

    const [marketUrl, setMarketUrl] = React.useState("")

    const [count, setCount] = React.useState(0)

    const [level, setlevel] = React.useState(0)

    const [type, setType] = React.useState(1)

    const [eligible, setEligible] = React.useState(true)

    const [maxAmount, setMaxAmount] = React.useState(2)

    const [redeemAmount, setRedeemAmount] = React.useState(1);

    const [totalClaimed, setTotalClaimed] = React.useState(0);
    
    const [amount, setAmount] = React.useState(0);

    const [arrNft, setArrNft] = React.useState([]);

    const [selectedToken, setSelectedToken] = React.useState(-1)

    const [selectedImage, setSelectedImage] = React.useState("")

    const [contractAddr, setContractAddr] = React.useState([]);

    const [imgMeta, setImgMeta] = React.useState([]);

    const [allowed, setAllowed] = React.useState(false);

    const [imgExt, setImgExt] = React.useState([]);

    const [approvedBanana, setApprovedBanana] = React.useState(false)

    const [mintVisible, setMintVisible] = React.useState(true);

    const [showCreateAddressModal, setShowCreateAddressModal] = React.useState(false);

    const countDownDate = new Date("2022-08-31T22:00:00.000-04:00").getTime();
    const [showModal, setShowModal] = React.useState(true);

    const [countDown, setCountDown] = React.useState(
        countDownDate - new Date().getTime()
    );

    const [inventory, setInventory] = React.useState([])

    const [inventorys, setInventorys] = React.useState([])

    const [redeemed, setRedeemed] = React.useState([])

    const [dropdown, setDropdown] = React.useState("1")

    const [items, setItems] = React.useState(false)

    const [nft, setNft] = React.useState(false)

    const [solanaNft, setSolanaNft] = React.useState([])

    const [reedemnft, setReedemNft] = React.useState(false)

    const [choosenft, setChooseNft] = React.useState(false)

    const [day,setDay] = React.useState(0)
    const [hour,setHour] = React.useState(0)
    const [minute,setMinute] = React.useState(0)
    const [second,setSecond] = React.useState(0)

    const [loading, setLoading] = React.useState(false)

    const [marketId, setMarketId] = React.useState("")
    const [img, setImg] = React.useState("")
    const [title, setTitle] = React.useState("")
    const [description, setDescription] = React.useState("")
    const [solanaAddress, setSolanaAddress] = React.useState("")
    const [addressTo, setAddressTo] = React.useState("")
    const [transferItem, setTransferItem] = React.useState(false)
    const [transferAmount, setTransferAmount] = React.useState(0)
    
    const [tranferJungle , setTransferJungle] = React.useState(false)
    const [jungleAddress , setJungleAddress] = React.useState("")
    const [jungleTransferAmount , setJungleTransferAmount] = React.useState(0)


    const handleLoad = React.useMemo(() => {
        setLoaded(true)
    }, []);

    React.useEffect(async() => {
        if(web3Provider){
            getInventory()
            getRedeem()
            checkWhitelist()
            getAccountBalance()
            //loadSolanaNft()
        }

        if(web3Provider == null){
            setTimeout( () => {
                setCount(count+1);
            },200)
        }

        if(count >= 14){
            navigate('/connect')
        }
    }, [web3Provider, count])

    React.useEffect(async() => {
        if(address != null){
            filterInventory(type)
        }
    }, [type])

    

    const setDetail = (object) => {
        setMarketId(object.id)
        setContractAddr(object.contract_address)
        setImgMeta(object.image_metadata)
        setImgExt(object.ext)
        setItems(true)
        setImg(object.image_url)
        setTicker(object.ticker)
        setMarketUrl("https://opensea.io/assets/ethereum/"+object.contract_address+"/"+object.tokenId)
        if(object.tokenId != null && object.tokenId!= undefined){
            setTokenId(object.tokenId)
            if(object.ticker == "eth"){
                setMarketUrl("https://opensea.io/assets/ethereum/"+object.contract_address+"/"+object.tokenId)
            }else{
                setMarketUrl("https://magiceden.io/item-details/"+object.tokenId)
            }
        }else{
            setTokenId(-1)
        }
        if(object.amount > 0){
            setAmount(object.amount)
        }else{
            setAmount(object.claimed_amount)
        }
        setTitle(object.title)
        setDescription(object.description)
    }

    const chooseNft = (object) => {
        console.log("token id",object.tokenId)
        setSelectedToken(object.tokenId)
        setSelectedImage(object.image)
        let arrdata = arrNft;
        arrdata.map( (nftData) => {
            if(nftData.tokenId == object.tokenId){
                nftData.style = "inventory-items-selected"
            }else{
                nftData.style = "inventory-items"
            }
        })
        setArrNft(arrdata)
    }

    const checkWhitelist = () => {
        /*
        whitelist.map((addr)=>{
            if(addr.toLowerCase() == address.toLowerCase()){
                setAllowed(true)
            }
        })
        */
        setAllowed(true)
    }

    const chooseNftSol = (object) => {
        setSelectedToken(object.mintAddress.toBase58())
        setSelectedImage(object.image)
        let arrdata = solanaNft;
        arrdata.map( (nftData) => {
            if(nftData.mintAddress.toBase58() == object.mintAddress.toBase58()){
                nftData.style = "inventory-items-selected"
            }else{
                nftData.style = "inventory-items"
            }
        })
        setSolanaNft(arrdata)
    }

    const closeModal = () => {
        setItems(false)
    }

    const reedemnfts = async() =>{
        if(selectedImage != ""){
            if(ticker == "eth"){
                await signNft()
            }else{
                //toast.error("solana nft")
                redeemSolNft()
            }
        }else{
            toast.error("you must select an nft to proceed")
        }
    }

    const checkAddressExistence = async ( transferFunction) => {
        try {
            const resp = await axios.get(base_uri + "/checkUserAccount?address=" + addressTo, {
                headers: {
                    api_key: "R8NQzvVkmt5g2n5BNr6kkfEhD8Jm6KLzLPf5lukrz7zMJjIPb9BDhbpQPOQ4nB4m",
                },
            });
            const exists = resp.data.exists;
            if (!exists) {
                setShowCreateAddressModal(true);
                setTransferFunction(() => transferFunction); // Save the function as a state
            }
            return exists;
        } catch (error) {
            console.error("Address checking failed:", error);
            return false;
        }
    };

    const transferItems = async () => {
        try {
          if (transferAmount > 0) {


            const addressExists = await checkAddressExistence( transferItems);

            if (!addressExists) {
                return;
            }
            toast.loading("Transfer in Progress");
            setLoading(true)
            let now = Math.floor(Date.now() / 1000);
            const body = {
              address_to: addressTo,
              address_from: address,
              marketplace_id: marketId,
              amount: transferAmount,
              timestamp: now,
            };
            const hashkey = enc.Hex.stringify(
              HmacSHA256(JSON.stringify(body), process.env.REACT_APP_SECRET_KEY)
            );
            const resp = await axios.post(
              base_uri + "/transferItem",
              body,
              {
                headers: {
                  api_key:
                    "R8NQzvVkmt5g2n5BNr6kkfEhD8Jm6KLzLPf5lukrz7zMJjIPb9BDhbpQPOQ4nB4m", //the token is a variable which holds the token
                  hash_key: hashkey,
                },
              }
            );
            console.log(resp.data);
            setLoading(false)
            closeTransfer();
            toast.dismiss();
            toast.success("Transfer Successful");
            getInventory();
          }
        } catch (error) {
          console.error("Transfer failed:", error);
          toast.dismiss();
          setLoading(false)
          toast.error("Transfer failed. Please try again.");
        }
    };

    const transferJungle = async () => {
        try {
          if (jungleTransferAmount > 0) {


            const addressExists = await checkAddressExistence(transferJungle);
            if (!addressExists) {
                return;
            }
            toast.loading("Transfer in Progress");
            setLoading(true)
            let now = Math.floor(Date.now() / 1000);
            const body = {
              address_to: addressTo,
              address_from: address,
              amount: jungleTransferAmount,
              timestamp: now,
            };
            const hashkey = enc.Hex.stringify(
              HmacSHA256(JSON.stringify(body), process.env.REACT_APP_SECRET_KEY)
            );
            const resp = await axios.post(
              base_uri + "/transferJungle",
              body,
              {
                headers: {
                  api_key:
                    "R8NQzvVkmt5g2n5BNr6kkfEhD8Jm6KLzLPf5lukrz7zMJjIPb9BDhbpQPOQ4nB4m", //the token is a variable which holds the token
                  hash_key: hashkey,
                },
              }
            );
            console.log(resp.data);
            setLoading(false)
            closeTransfer();
            toast.dismiss();
            toast.success("Transfer Successful");
            getInventory();
          }
        } catch (error) {
          console.error("Transfer failed:", error);
          toast.dismiss();
          setLoading(false)
          toast.error("Transfer failed. Please try again.");
        }
    };

    const signNft = async() => {
        setArrNft([])

        let nows = Math.floor(Date.now() / 1000)
        const bodys = {
            address: address,
            marketplace_id: marketId,
            amount: 1,
            tokenId : selectedToken,
            img: selectedImage,
            tokenAddr: contractAddr,
            timestamp: nows
        }
        const hashkeys = enc.Hex.stringify(HmacSHA256(JSON.stringify(bodys),process.env.REACT_APP_SECRET_KEY))

        const res = await axios.post(base_uri+'/redeem',
        bodys,
        {
        headers: {
            api_key: 'R8NQzvVkmt5g2n5BNr6kkfEhD8Jm6KLzLPf5lukrz7zMJjIPb9BDhbpQPOQ4nB4m', //the token is a variable which holds the token
            hash_key: hashkeys
        }
        })

        const signer = web3Provider?.getSigner();
        const contract = new ethers.Contract(redeemContract, redeemAbi, signer);
        try{
            const tx = await contract.redeemReward(contractAddr,selectedToken,1,res.data.nonce,res.data.hash,res.data.signature)

            let receipt = await toast.promise(
            tx.wait(),
            {
                pending: 'Redeem in Progress',
                success: 'Redeem Success',
                error: 'Redeem Failed'
            })


            let now_redeem = Math.floor(Date.now() / 1000)
            const body_redeem = {
                address: address,
                marketplace_id: marketId,
                amount: 1,
                tokenId : selectedToken,
                img: selectedImage,
                tokenAddr: contractAddr,
                timestamp: now_redeem
            }
            const hashkey_redeem = enc.Hex.stringify(HmacSHA256(JSON.stringify(body_redeem),process.env.REACT_APP_SECRET_KEY))

            const response = await axios.post(base_uri+'/redeemed',
            body_redeem,
            {
            headers: {
                api_key: 'R8NQzvVkmt5g2n5BNr6kkfEhD8Jm6KLzLPf5lukrz7zMJjIPb9BDhbpQPOQ4nB4m', //the token is a variable which holds the token
                hash_key: hashkey_redeem
            }
            })

        }catch(err){
            //refund token that has been taken out

            let now_refund = Math.floor(Date.now() / 1000)
            const body_refund = {
                address: address,
                marketplace_id: marketId,
                amount: 1,
                tokenId : selectedToken,
                img: selectedImage,
                tokenAddr: contractAddr,
                timestamp: now_refund
            }
            const hashkey_refund = enc.Hex.stringify(HmacSHA256(JSON.stringify(body_refund),process.env.REACT_APP_SECRET_KEY))

            const response = await axios.post(base_uri+'/refund',
            body_refund,
            {
            headers: {
                api_key: 'R8NQzvVkmt5g2n5BNr6kkfEhD8Jm6KLzLPf5lukrz7zMJjIPb9BDhbpQPOQ4nB4m', //the token is a variable which holds the token
                hash_key: hashkey_refund
            }
            })
            //await closeAll()
            
            toast.error("Redeem Failed")
        }
        closeAll()
    }

    const redeemSolNft = async() => {
        // const resp = await axios.post(base_uri+'/sign',
        // {
        //     address: address,
        //     amount: 1
        // },
        // {
        // headers: {
        //     api_key: 'R8NQzvVkmt5g2n5BNr6kkfEhD8Jm6KLzLPf5lukrz7zMJjIPb9BDhbpQPOQ4nB4m' //the token is a variable which holds the token
        // }
        // })

        try{

            // let receipt = await toast.promise(
            // {
            //     pending: 'Redeem in Progress',
            //     success: 'Redeem Success',
            //     error: 'Redeem Failed'
            // })
            toast.loading('Redeem in Progress')

            let now = Math.floor(Date.now() / 1000)
            const body = {
                address: address,
                solAddr: solanaAddress,
                marketplace_id: marketId,
                amount: 1,
                tokenId : selectedToken,
                img: selectedImage,
                tokenAddr: contractAddr,
                timestamp: now
            }
            const hashkey = enc.Hex.stringify(HmacSHA256(JSON.stringify(body),process.env.REACT_APP_SECRET_KEY))

            const response = await axios.post(base_uri+'/redeemSol',
            body,
            {
            headers: {
                api_key: 'R8NQzvVkmt5g2n5BNr6kkfEhD8Jm6KLzLPf5lukrz7zMJjIPb9BDhbpQPOQ4nB4m', //the token is a variable which holds the token
                hash_key: hashkey
            }
            })
            toast.dismiss()
            getInventory()
            getRedeem()
            checkWhitelist()
            closeAll()
            toast.success("Redeem Success")
        }catch(err){
            toast.error("Redeem Failed")
            closeAll()
        }
    }

    const getAccountBalance = async() => {
        const resp = await axios.get(base_uri+'/getBalance?address='+ address,
        {
        headers: {
            api_key: 'R8NQzvVkmt5g2n5BNr6kkfEhD8Jm6KLzLPf5lukrz7zMJjIPb9BDhbpQPOQ4nB4m' //the token is a variable which holds the token
        }
        })

        setTotalClaimed(resp.data.result[0].total_claimed)

    }


    const getInventory = async() => {
        const resp = await axios.get(base_uri+'/getInventory?address='+ address,
        {
        headers: {
            api_key: 'R8NQzvVkmt5g2n5BNr6kkfEhD8Jm6KLzLPf5lukrz7zMJjIPb9BDhbpQPOQ4nB4m' //the token is a variable which holds the token
        }
        })
        let inv = resp.data.result;
        setInventorys(inv)
        let arr = []

        inv.map(
            (obj) => {
                if(obj.id <= 2 && obj.amount > 0){
                    arr.push(obj)
                }
            }
        )
        if(window.innerWidth > 550 && arr.length > 0){
            let object = arr[0]
            setMarketId(object.id)
            setImg(object.image_url)
            if(object.amount > 0){
                setAmount(object.amount)
            }else{
                setAmount(object.claimed_amount)
            }
            setTitle(object.title)
            setDescription(object.description)

            setItems(true)
        }else{
            setItems(false)
        }
        setInventory(arr)
    }


    const getRedeem = async() => {
        const resp = await axios.get(base_uri+'/getRedeem?address='+ address,
        {
        headers: {
            api_key: 'R8NQzvVkmt5g2n5BNr6kkfEhD8Jm6KLzLPf5lukrz7zMJjIPb9BDhbpQPOQ4nB4m' //the token is a variable which holds the token
        }
        })
        setRedeemed(resp.data.result)
        
    }

    const filterInventory = (types) =>{
        let arr = []
        let inv = inventorys
        if(types == 1){
            inv.map(
                (obj) => {
                    if(obj.id <= 2 && obj.amount > 0){
                        arr.push(obj)
                    }
                }
            )
        }else if(types == 2){
            inv.map(
                (obj) => {
                    if(obj.id >= 3 && obj.amount > 0){
                        arr.push(obj)
                    }
                }
            )
        }
        else if(types == 3){
            
            redeemed.map(
                (obj) => {
                    obj.amount = 1
                    obj.image_url = obj.img
                    arr.push(obj)
                }
            )
            inv.map(
                (obj) => {
                    if(obj.claimed_amount > 0){
                        arr.push(obj)
                    }
                }
            )
        }
        if(window.innerWidth > 550 && arr.length > 0){
            let object = arr[0]
            setMarketId(object.id)
            setImg(object.image_url)
            if(object.amount > 0){
                setAmount(object.amount)
            }else{
                setAmount(object.claimed_amount)
            }
            setTitle(object.title)
            setDescription(object.description)

            setItems(true)
        }else{
            setItems(false)
        }
        if(arr.length <= 0){
            setItems(false)
        }
        setInventory(arr)
    }

    const filterInventorys = (search) =>{
        let arr = []
        let inv = inventorys
        let types = type
        if(types == 1){
            inv.map(
                (obj) => {
                    if(obj.id <= 2 && obj.amount > 0 && obj.title.toLowerCase().includes(search.toLowerCase())){
                        arr.push(obj)
                    }
                }
            )
        }else if(types == 2){
            inv.map(
                (obj) => {
                    if(obj.id >= 3 && obj.amount > 0 && obj.title.toLowerCase().includes(search.toLowerCase())){
                        arr.push(obj)
                    }
                }
            )
        }
        else if(types == 3){
            
            redeemed.map(
                (obj) => {
                    if(obj.title.toLowerCase().includes(search.toLowerCase())){
                        obj.image_url = obj.img
                        arr.push(obj)
                    }
                }
            )
            inv.map(
                (obj) => {
                    if(obj.claimed_amount > 0){
                        arr.push(obj)
                    }
                }
            )
        }
        setInventory(arr)
    }

    const setTab = (index) =>{
        setSearch("")
        setDropdown(index)
        setInventory([])
       if(index == 1){
            setType(1)
            document.getElementById("tab-1").classList.remove('button-tab');
            document.getElementById("tab-1").classList.add('button-tab-active');
            document.getElementById("tab-2").classList.remove('button-tab-active');
            document.getElementById("tab-2").classList.remove('button-tab');
            document.getElementById("tab-2").classList.add('button-tab');
            document.getElementById("tab-3").classList.remove('button-tab-active');
            document.getElementById("tab-3").classList.remove('button-tab');
            document.getElementById("tab-3").classList.add('button-tab');
       }
       if(index == 2){
            setType(2)
            document.getElementById("tab-2").classList.remove('button-tab');
            document.getElementById("tab-2").classList.add('button-tab-active');
            document.getElementById("tab-1").classList.remove('button-tab-active');
            document.getElementById("tab-1").classList.remove('button-tab');
            document.getElementById("tab-1").classList.add('button-tab');
            document.getElementById("tab-3").classList.remove('button-tab-active');
            document.getElementById("tab-3").classList.remove('button-tab');
            document.getElementById("tab-3").classList.add('button-tab');
       }
       if(index == 3){
            setType(3)
            document.getElementById("tab-3").classList.remove('button-tab');
            document.getElementById("tab-3").classList.add('button-tab-active');
            document.getElementById("tab-2").classList.remove('button-tab-active');
            document.getElementById("tab-2").classList.remove('button-tab');
            document.getElementById("tab-2").classList.add('button-tab');
            document.getElementById("tab-1").classList.remove('button-tab-active');
            document.getElementById("tab-1").classList.remove('button-tab');
            document.getElementById("tab-1").classList.add('button-tab');
       }
    }

    const openRedeem =() =>{
        setReedemNft(true)
    }

    const closeRedeem =() =>{
        setReedemNft(false)
    }

    const openTransfer =() =>{
        setTransferItem(true)
    }

    const closeTransfer =() =>{
        setTransferItem(false)
        setTransferAmount(0)
    }

    const closeAll =() =>{
        setReedemNft(false)
        setTransferJungle(false)
        setTransferItem(false)
        setChooseNft(false)
        window.location.reload()
    }

    const openJungle =() =>{
        setTransferJungle(true)
    }

    const closeJungle =() =>{
        setTransferJungle(false)
    }

    const handleCreateAddress = async (transferFunction) => {
        try {
          const body = {
            address: addressTo,
            balance: 0,
            total_claimed: 0
          };
      
          const hashkey = enc.Hex.stringify(
            HmacSHA256(JSON.stringify(body), process.env.REACT_APP_SECRET_KEY)
          );
      
          const response = await axios.post(
            base_uri + "/createUserAccount",
            body,
            {
              headers: {
                api_key:
                  "R8NQzvVkmt5g2n5BNr6kkfEhD8Jm6KLzLPf5lukrz7zMJjIPb9BDhbpQPOQ4nB4m", 
                hash_key: hashkey,
              },
            }
          );
      
            setShowCreateAddressModal(false);
            transferFunction();
        } catch (error) {
          console.error("Error in handleCreateAddress:", error);
        }
      };

    const openChoose = ()=>{

        if(ticker == "sol"){
            if(solanaAddress == ""){
                toast.error("Solana Address Cannot be Empty")
            }else{
                setReedemNft(false)
                setChooseNft(true)
                loadSolanaNft()
            }
        }else{
            setReedemNft(false)
            setChooseNft(true)
            loadBananaNft()
        }
    }

    const increment = () => {
        if(redeemAmount < maxAmount){
            if(redeemAmount < 1){
                setRedeemAmount(redeemAmount+1);
            }
        }
    }

    const decrement = () => {
        if(redeemAmount > 0){
            setRedeemAmount(redeemAmount-1);
        }
    }

    const incrementTransfer = () => {
        if(transferAmount < amount){
            setTransferAmount(transferAmount+1);
        }
    }

    const decrementTransfer = () => {
        if(transferAmount > 0){
            setTransferAmount(transferAmount-1);
        }
    }

    const ipfs_url_from_hash = (h) => {
        return "https://cloudflare-ipfs.com/ipfs/" + h.replace("ipfs:/","");
    }

    const loadBananaNft = async() => {
        setLoading(true)
        const signer = web3Provider?.getSigner();
        const contract = new ethers.Contract(redeemContract, redeemAbi, signer);
        const nftContract = new ethers.Contract(contractAddr, mutantbananaAbi, signer);
        const nft = await contract.rewardNfts(contractAddr)
        let arrUri = Array()
        await Promise.all(nft.map( async(nftData)=>{

            if(marketId == 10){
                let obj = {}
                obj.tokenId = nftData.toNumber()
                obj.style = 'inventory-items'
                obj.image = "https://raffle.supremekong.com/images/"+nftData.toNumber()+".png"
                arrUri.push(obj)
            }else if(marketId == 19){
                let obj = {}
                obj.tokenId = nftData.toNumber()
                obj.style = 'inventory-items'
                obj.image = "https://assets.eternalsxyz.io/eternalsxyz/images/"+nftData.toNumber()+".png"
                arrUri.push(obj)
            }else if(marketId == 11){
                let obj = {}
                obj.tokenId = nftData.toNumber()
                obj.style = 'inventory-items'
                obj.image = "https://assets.atsnft.io/ats/images/"+nftData.toNumber()+".png"
                arrUri.push(obj)
            }else if(marketId == 4){
                let obj = {}
                obj.tokenId = nftData.toNumber()
                obj.style = 'inventory-items'
                obj.image = supremebanana
                arrUri.push(obj)
            }else if(marketId == 25){
                let obj = {}
                obj.tokenId = nftData.toNumber()
                obj.style = 'inventory-items'
                obj.image = "https://gen2.supremekong.com/images/"+nftData.toNumber()+".jpg"
                arrUri.push(obj)
            }else if(marketId == 35){
                let obj = {}
                obj.tokenId = nftData.toNumber()
                obj.style = 'inventory-items'
                obj.image = "https://cloudflare-ipfs.com/ipfs/QmZwnDjhVMAyhzWdjV8gw97H5jgcF7oQQxvvG3g5QFUCUo/"+nftData.toNumber()+".png"
                arrUri.push(obj)
            }else if(marketId == 36){
                let obj = {}
                obj.tokenId = nftData.toNumber()
                obj.style = 'inventory-items'
                obj.image = "https://supreme-kong-assets.s3.us-east-2.amazonaws.com/images/1687320421256SacredShard.jpeg"
                arrUri.push(obj)
            }else{
                let uri = await nftContract.tokenURI(nftData.toNumber())
                let obj = {}
                if(uri.includes("ipfs:")){
                    uri = await ipfs_url_from_hash(uri);
                }
                const resp = await axios.get(uri)
                if(resp.data.image.includes("ipfs:")){
                    obj.image = await ipfs_url_from_hash(resp.data.image);
                }else{
                    obj.image = resp.data.image
                }
                obj.tokenId = nftData.toNumber()
                obj.style = 'inventory-items'
                arrUri.push(obj)
            }
        }))
        setLoading(false)
        setArrNft(arrUri)

    }


    const loadSolanaNft = async() => {

        setLoading(true)
        let owners = new PublicKey(redeemSolana)

        let collectionKey = new PublicKey(contractAddr)
        const myNfts = await metaplex.nfts().findAllByOwner({
            owner: owners
        });

        let arrNft = Array()

        await Promise.all(myNfts.map( async(nftData)=>{
            if(nftData.collection != null){
                if(collectionKey.toBase58() == nftData.collection.address.toBase58()){
                    try{
                    const resp = await axios.get(base_uri+"/proxy?targetServer="+encodeURIComponent(nftData.uri))
                    const json = resp.data
                    nftData.image = json.image
                    nftData.style = 'inventory-items'
                    arrNft.push(nftData)
                    }catch(e){

                    }
                }
            }
            })
        )
        setLoading(false)
        setSolanaNft(arrNft)  
    }



    return (
        <>
        <Preloader handleLoad={handleLoad} />
        <Header siteTitle={`Title`} />
        <RingSpinnerOverlay loading={loading} overlayColor="rgba(255,255,255,0.2)">

        </RingSpinnerOverlay>
        <div className='Inventory__content'>
            <div className='container'>
                <div className="total-claim">
                    <p style={{marginRight:"5px"}}>Total Claimed : </p>
                    <p>{totalClaimed.toLocaleString()} (USD)</p>    
                </div>
                <p className="inventory-title">Inventory</p>

                <a>
                    <button className="DiscordButton" onClick={()=>{openJungle()}}>Transfer $KONG</button>
                </a>
            </div>
            <div className="inventory-tab" >
                <button id="tab-1" className="button-tab-active" onClick={() => {setTab(1)}}>ITEMS</button>
                <button id="tab-2" className="button-tab" onClick={() => {setTab(2)}} >REDEEMABLE TOKENS</button>
                <button id="tab-3" className="button-tab" onClick={() => {setTab(3)}} >REDEEMED TOKENS</button>
            </div>

            <div className="inventory-select">
                <select name="category" id="category" className="select-cat" value={dropdown} onChange={(e) => {setTab(e.target.value)}}>
                    <option value="1">ITEMS</option>
                    <option value="2">REDEEMABLE TOKENS</option>
                    <option value="3">REDEEMED TOKENS</option>
                </select>
            </div>

            <div className="inventory-container">
                <div className="inventory-left">
                    <div className="inventory-contents">
                        <div className="inventory-search">
                    <input type="search" placeholder="Search Items" className="text-inven-search" value={search} onChange={(e)=>{setSearch(e.target.value)}} onKeyPress={event => {
                        if (event.key === 'Enter') {
                            filterInventorys(event.target.value)
                        }
                    }} onMouseLeave={event => {
                        filterInventorys(event.target.value)
                    }}>
                    </input>
                        </div>
                        <div className="inventory-content">
                            {inventory.map((object, i) =>
                                <div key={i} className="inventory-items" onClick={() => {setDetail(object)}}>
                                    <img src={object.image_url}></img>
                                    { object.amount > 0 ?
                                        <div className="amount">{object.amount}</div>
                                        :
                                        <div className="amount">{object.claimed_amount}</div>
                                    }
                                </div>
                            )}

                            {inventory.length == 0 &&
                                <div className="inventory-items">
                                    {dropdown == 1 &&
                                        <>No Items Available</>
                                    }

                                    {dropdown == 2 &&
                                        <>No Redeemable Tokens Available</>
                                    }

                                    {dropdown == 3 &&
                                        <>No Redeemed Tokens Available</>
                                    }
                                </div>
                            }
                        </div>
                    </div>
                </div>
                <div className="inventory-right">
                    { items &&
                        <div className="inventory-detail">
                            <img src={img}></img>
                            <p style={{fontSize:'12px',color:"white",fontFamily:"Lato",marginBottom:"20px",marginTop:"10px"}}>Owned : {amount}</p>
                            <h4 style={{marginBottom:'20px',color:'white'}}>{title}</h4>
                            { ( type != 3) &&
                                <p style={{fontSize:'12px',color:"white",fontFamily:"Lato",marginBottom:"20px"}}>{description}</p>
                            }

                            { ( type == 3 && tokenId == -1) &&
                                <p style={{fontSize:'12px',color:"white",fontFamily:"Lato",marginBottom:"20px"}}>{description}</p>
                            }
                            { (tokenId != -1 && type == 3) &&
                                <p style={{fontSize:'12px',color:"white",fontFamily:"Lato",marginBottom:"20px"}}>
                                    <a href={marketUrl} target='_blank' rel="noreferrer" style={{textOverflow:"ellipsis"}}>{title} #{tokenId} - {ticker == "eth" && "Opensea"} {ticker == "sol" && "Magic Eden"}</a>
                                </p>
                            }
                            { marketId == 2 &&
                                <a href='/staking'>
                                    <button className="DiscordButton">USE</button>
                                </a>
                            }
                            { (marketId > 2 && type == 2 && allowed == true) &&
                                <a>
                                    <button className="DiscordButton" onClick={()=>{openRedeem()}}>Redeem NFT</button>
                                </a>
                            }
                            { type != 3 &&
                                <a>
                                    <button className="DiscordButton" onClick={()=>{openTransfer()}}>Transfer Item</button>
                                </a>
                            }

                        </div>
                    }
                </div>
                <div className="inventory-modal">
                    { items &&
                        <div className="inventory-detail-modal">
                            <img src={img}></img>
                            <p style={{fontSize:'12px',color:"white",fontFamily:"Lato",marginBottom:"20px",marginTop:"10px"}}>Owned : {amount}</p>
                            <h4 style={{marginBottom:'20px',color:'white'}}>{title}</h4>
                            { (type != 3) &&
                                <p style={{fontSize:'12px',color:"white",fontFamily:"Lato",marginBottom:"20px"}}>{description}</p>
                            }
                            { ( type == 3 && tokenId == -1) &&
                                <p style={{fontSize:'12px',color:"white",fontFamily:"Lato",marginBottom:"20px"}}>{description}</p>
                            }
                            
                            { (tokenId != -1 && type == 3) &&
                                <p style={{fontSize:'12px',color:"white",fontFamily:"Lato",marginBottom:"20px"}}>
                                    <a href={marketUrl} target='_blank' rel="noreferrer" style={{textOverflow:"ellipsis"}}>{title} #{tokenId} - {ticker == "eth" && "Opensea"} {ticker == "sol" && "Magic Eden"}</a>
                                </p>
                            }

                            <div>
                                { marketId == 2 &&
                                    <a href='/staking'>
                                        <button className="UseButtonInven">USE</button>
                                    </a>
                                }
                                { (marketId > 2 && type == 2 && allowed == true) &&
                                    <a>
                                        <button className="UseButtonInven" onClick={()=>{openRedeem()}}>Redeem NFT</button>
                                    </a>
                                }
                                { type != 3 &&
                                <a>
                                    <button className="UseButtonInven" onClick={()=>{openTransfer()}}>Transfer Item</button>
                                </a>
                                }
                            </div>

                            <button className="CloseInvenButton" onClick={closeModal}>Close</button>
                        </div>
                    }
                </div>

                {
                    reedemnft &&
                        <>
                            <div className="inventory-redeem-modal">
                                <div className="redeem-title">REDEEM NFT</div>
                                <div className="redeem-title-nft">{title}</div>
                                <img src={img}></img>
                                <div className='amountRedeemBtn'>
                                    <div className='decrement' >
                                    </div>

                                    <div className='amountReedemTxt'>
                                        {redeemAmount}
                                    </div>

                                    <div className='increment'>
                                    </div>
                                </div>

                                { ticker == "sol" &&
                                    <input className='amountRedeemInput' type="text" placeholder="Solana Address" value={solanaAddress} 
                                    onChange={(e) => setSolanaAddress(e.target.value)}></input>
                                }
                                <div className="RedeemButton">
                                    <button className="DiscordButton" onClick={()=>{openChoose()}} style={{paddingLeft:"80px",paddingRight:"80px",marginTop:"10px"}}>
                                        Redeem
                                    </button>
                                    <button className="DiscordButton" onClick={()=>{closeRedeem()}} style={{paddingLeft:"80px",paddingRight:"80px",marginTop:"10px"}} >
                                        Close
                                    </button>
                                </div>
                            </div>
                        </>
                }

                {
                    choosenft &&
                        <>
                            <div className="inventory-redeem-modal">
                                <div className="redeem-title">CHOOSE NFT</div>
                                <div className="redeem-title-nft">{title}</div>
                                {
                                    (arrNft.length == 0 && solanaNft.length == 0) &&
                                     <>Not Available to Redeem</>
                                }
                                <div className="inventory-content">
                                    { ticker == "eth" &&
                                        arrNft.map((object, i) =>
                                            <div key={i} className={object.style} onClick={() => {chooseNft(object)}}>
                                                <img src={object.image}></img>
                                            </div>
                                        )
                                    }

                                    { ticker == "sol" &&
                                        solanaNft.map((object, i) =>
                                            <div key={i} className={object.style} onClick={() => {chooseNftSol(object)}}>
                                                <img src={object.image}></img>
                                            </div>
                                        )
                                    }
                                </div>
                                
                                <div className="RedeemButton">
                                    <button className="DiscordButton" style={{paddingLeft:"80px",paddingRight:"80px",marginTop:"10px"}} onClick={()=>{reedemnfts()}}>
                                        Redeem
                                    </button>

                                    <button className="DiscordButton" onClick={()=>{closeAll()}} style={{paddingLeft:"80px",paddingRight:"80px",marginTop:"10px"}} >
                                        Close
                                    </button>

                                </div>
                            </div>
                        </>
                }

                {
                    transferItem &&
                        <>
                            <div className="inventory-redeem-modal">
                                <div className="redeem-title">Transfer Item</div>
                                <div className="redeem-title-nft">{title}</div>
                                <img src={img}></img>
                                <div className='amountRedeemBtn'>
                                    <div className='decrement' onClick={decrementTransfer}>
                                    </div>

                                    <div className='amountReedemTxt' >
                                        {transferAmount}
                                    </div>

                                    <div className='increment' onClick={incrementTransfer}>
                                    </div>
                                </div>

                                <input className='amountRedeemInput' type="text" placeholder="Wallet Address" value={addressTo} 
                                onChange={(e) => setAddressTo(e.target.value)}></input>
                                <div className="RedeemButton">
                                    <button className="DiscordButton" onClick={()=>{transferItems()}} style={{paddingLeft:"80px",paddingRight:"80px",marginTop:"10px"}}>
                                        Transfer
                                    </button>
                                    <button className="CloseInvenButton" onClick={()=>{closeTransfer()}} style={{paddingLeft:"80px",paddingRight:"80px",marginTop:"10px"}} >
                                        Close
                                    </button>
                                </div>
                            </div>
                        </>
                }


                {
                    tranferJungle &&
                        <>
                            <div className="inventory-redeem-modal">
                                <div className="redeem-title">Transfer $KONG</div>
                                <input className='amountRedeemInput' type="number" placeholder="Amount" value={jungleTransferAmount} 
                                onChange={(e) => setJungleTransferAmount(parseInt(e.target.value))} min="0"></input>

                                <input className='amountRedeemInput' type="text" placeholder="Wallet Address" value={addressTo} 
                                onChange={(e) => setAddressTo(e.target.value)}></input>

                                <div className="RedeemButton">
                                    <button className="DiscordButton" onClick={()=>{transferJungle()}} style={{paddingLeft:"80px",paddingRight:"80px",marginTop:"10px"}}>
                                        Transfer
                                    </button>
                                    <button className="CloseInvenButton" onClick={()=>{closeJungle()}} style={{paddingLeft:"80px",paddingRight:"80px",marginTop:"10px"}} >
                                        Close
                                    </button>
                                </div>
                            </div>
                        </>
                }

                {
                    showCreateAddressModal &&
                    <div className="inventory-redeem-modal">
                        <div className="redeem-title-nft">The address does not exist. Do you want to create it?</div>
                        <div className="RedeemButton">
                            <button className="DiscordButton" onClick={()=>{handleCreateAddress(transferFunction)}} style={{paddingLeft:"80px",paddingRight:"80px",marginTop:"10px"}}>
                                YES
                            </button>
                            <button className="CloseInvenButton" onClick={()=>{setShowCreateAddressModal(false)}} style={{paddingLeft:"80px",paddingRight:"80px",marginTop:"10px"}} >
                                NO
                            </button>
                        </div>
                    </div>
                }
            </div>
        </div>
        </>
    );
}

export default Inventory;
