import * as React from 'react';
import Logo from "../../assets/icons/sk_logo.svg";
import ConnectButton from '../lib/ConnectWalletButton/ConnectButton';
import TwitterIcon from '../../assets/icons/twitter.svg';
import InstagramIcon from '../../assets/icons/instagram.svg';
import DiscordIcon from '../../assets/icons/discord.svg';
import navigateTo from '../../utils/navigateTo';
import { motion } from 'framer-motion';

import closeIcon from '../../assets/icons/close.svg';
import burgerIcon from '../../assets/icons/burger.svg';
import './HeaderWallet.scss';

import { useWeb3Context } from '../../context'
import axios from "axios";

const menuBtn = {
  open: { rotate: 0 },
  closed: { rotate: 180 },
};

const base_uri = "https://api.supremekong.com";
//const base_uri = 'http://localhost:8001'

const nav = {
  open: { opacity: 1, x: 0 },
  closed: { opacity: 0, x: '130%' },
};
const HeaderWallet = ({ siteTitle,balances }) => {
  const tokens = localStorage.getItem('token');
  axios.defaults.headers.common['Authorization'] = `Bearer ${tokens}`;
  axios.defaults.headers.common['Content-Type'] = 'application/json';
  const [open, setOpen] = React.useState(false);
  const { web3Provider, connect, disconnect, address } = useWeb3Context()
  const [balance,setBalance] = React.useState(0)
  const getBalance = async() => {
    const res = await axios.get(base_uri + '/getBalance?address='+address,
    {
    headers: {
        api_key: 'R8NQzvVkmt5g2n5BNr6kkfEhD8Jm6KLzLPf5lukrz7zMJjIPb9BDhbpQPOQ4nB4m' //the token is a variable which holds the token
    },
    withCredentials: true
    })
    setBalance(res.data.result[0].balance)
  }
  function onNavigate(e) {
    navigateTo(e);
  }

  function handleOpen() {
    setOpen(!open);
  }

  React.useEffect(() => {
    if(web3Provider){
      getBalance()
    }
  }, [web3Provider,balances]);

  React.useEffect(() => {
    if(balances){
      setBalance(balances)
    }else{
      getBalance()
    }
  }, [balances]);
  return (
    <header className='HeaderWallet'>
      <div className='HeaderWallet__content'>
        <div className='HeaderWallet__logo' >
          <div style={{display:"flex",}}>
            <a href='/staking'>
              <img src={Logo} alt='' />
            </a>
            <div className='wallet-balance-desktop'>
              <ConnectButton balances={{"balances":balance}}/>
            </div>
          </div>
        </div>
        
        <ul className='HeaderWallet__list'>
          <li>
            <a href='/staking'>
              Stake
            </a>
          </li>
          {/* <li>
            <a href='/mutate'>
              Mutate
            </a>
          </li> */}
          <li>
            <a href='/marketplace'>
              Marketplace
            </a>
          </li>
          <li>
            <a href='/inventory'>
              Inventory
            </a>
          </li>
          <li>
            <a href='/transaction'>
              History
            </a>
          </li>
          <li>
            <a href='/stats'>
              Stats
            </a>
          </li>
          <li>
            <a href='/faq'>
              FAQ
            </a>
          </li>
        </ul>
        <ul className='HeaderWallet__social'>
          {/* <a
            className='HeaderWallet__social__anchor'
            href='https://twitter.com/Supremekongnft'
            target='_blank'
            rel='noreferrer'
          >
            <img src={TwitterIcon} alt='' />
          </a>
          <a
            className='HeaderWallet__social__anchor'
            href='https://instagram.com/supremekong.nft'
            target='_blank'
            rel='noreferrer'
          >
            <img src={InstagramIcon} alt='' />
          </a>
          <a
            className='HeaderWallet__social__anchor'
            href='https://discord.com/invite/supremekong'
            target='_blank'
            rel='noreferrer'
          >
            <img src={DiscordIcon} alt='' />
          </a> */}
          <div
            className='HeaderWallet__social__anchor'
            className='header-border'
            href='https://discord.com/invite/supremekong'
            target='_blank'
            rel='noreferrer'
          >
            {address &&
              address.substring(0,3) + "..."+ address.substring(address.length-3,address.length)
            }
          </div>
          {address &&
            <div
              className='HeaderWallet__social__anchor'
              className='header-border-btn'
              href='https://discord.com/invite/supremekong'
              target='_blank'
              rel='noreferrer'
            >
              <button className='DiscordButton' onClick={disconnect} style={{paddingLeft:"25px",paddingRight:"25px"}}>
                Disconnect
              </button>
            </div>
          }
        
        </ul>
        <motion.button
          animate={open ? 'open' : 'closed'}
          initial={'closed'}
          variants={menuBtn}
          onClick={handleOpen}
          className='HeaderWallet__menu__btn'
        >
          <img src={open ? closeIcon : burgerIcon} alt='' />
        </motion.button>
      </div>
      <motion.nav
        animate={open ? 'open' : 'closed'}
        initial={'closed'}
        variants={nav}
        onClick={handleOpen}
        className='Header__menu__btn'
        className='Header__motion__nav'
      >
        <ul className='Header__motion__nav-anchors'>
          <li style={{textAlign:"center",fontSize:"20px"}}>
            {address &&
              address.substring(0,3) + "..."+ address.substring(address.length-3,address.length)
            }
          </li> 
          <li style={{textAlign:"center",fontSize:"20px"}} >
              <button className='DiscordButton' onClick={disconnect} style={{paddingLeft:"25px",paddingRight:"25px",marginLeft:"auto",marginRight:"auto"}}>
                Disconnect
              </button>
          </li> 
          <li>
            <a href='/staking'>
              Stake
            </a>
          </li> 
          <li>
            <a href='/marketplace'>
              Marketplace
            </a>
          </li>
          <li>
            <a href='/inventory'>
              Inventory
            </a>
          </li>
          <li>
            <a href='/transaction'>
              History
            </a>
          </li>
          <li>
            <a href='/stats'>
              Stats
            </a>
          </li>
          <li>
            <a href='/faq'>
              FAQ
            </a>
          </li>
          {/* <li>
            <a href='/mutate'>
              Mutate
            </a>
          </li> */}
        </ul>
        <ul className='Header__social'>
          <a
            className='Header__social__anchor'
            href='https://twitter.com/Supremekongnft'
            target='_blank'
            rel='noreferrer'
          >
            <img src={TwitterIcon} alt='' />
          </a>
          <a
            className='Header__social__anchor'
            href='https://discord.com/invite/supremekong'
            target='_blank'
            rel='noreferrer'
          >
            <img src={DiscordIcon} alt='' />
          </a>
        </ul>
      </motion.nav>
    </header>
  );
};

export default HeaderWallet;
